import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import 'leaflet-geosearch/dist/geosearch.css';
import React from 'react';
import { useMap } from 'react-leaflet';

const SearchBar = () => {
    const map = useMap();
    const provider = new OpenStreetMapProvider();

    React.useEffect(() => {
        const searchControl = new GeoSearchControl({
            provider: provider,
            style: 'bar',
            showMarker: false
        });

        map.addControl(searchControl);
        return () => map.removeControl(searchControl);
    }, []);

    return null;
};
export default SearchBar;
