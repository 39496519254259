import React from 'react';
import { useLocation } from 'react-router-dom';

export default function useQuery(queryName: string) {
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    return React.useMemo(
        () => ({
            [queryName]: query.get(queryName) !== null ? query.get(queryName) : ''
        }),
        [query, queryName]
    );
}
