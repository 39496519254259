// material-ui
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

export const checkedIcon = <CheckBoxIcon fontSize="small" />;
export const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

export type ProjectEditPropsType = {
    projectId: number | string;
    refresh(e: any): void;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const projectInitialValues: any = {
    name: '',
    startDate: null,
    endDate: null,
    description: '',
    projectImage: '',
    partner: []
};

export const projectAddStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3
};
