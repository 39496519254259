/*eslint-disable*/
import { Link, Typography } from '@mui/material';

import langString from 'utils/langString';
import { setTargetOnBlur, setTargetOnChange } from './Service';

export const goToFormProfile = ({ xformId, formId, projectId, disabled }: any) => {
    // /project/:projectId/form/:formId/data
    // if (disabled) {
    //     return `/project/${projectId}/form/${formId}/${xformId}/details`;
    // }
    // return `/project/${projectId}/form/${formId}/${xformId}`;
    return `/project/${projectId}/form/${formId}/data`;
};

export const columns = ({ projectID, projectForms, dispatch, snackbarMsg, navigate, disabled }: any) => [
    {
        header: 'Form Name',
        accessor: 'title',
        content: (item: any) => (
            <Link
                style={{ cursor: 'pointer' }}
                onClick={(e: any) => navigate(goToFormProfile({ xformId: item.xformId, projectId: projectID, formId: item.id, disabled }))}
                variant="body2"
            >
                {item.title}
            </Link>
        )
    },
    {
        header: 'Creation Date',
        accessor: 'createdAt',
        content: (item: any) => <Typography>{new Date(item.createdAt).toLocaleDateString()} </Typography>
    },
    {
        header: 'Status',
        accessor: 'status',
        content: (item: any) => (
            <>
                {item.isActive ? (
                    <Typography sx={{ color: '#44bd32' }}>{langString('active')}, </Typography>
                ) : (
                    <Typography sx={{ color: '#c23616' }}>{langString('inavtive')}, </Typography>
                )}
                {item.isPublished ? (
                    <Typography sx={{ color: '#069BF5' }}>Published</Typography>
                ) : (
                    <Typography sx={{ color: '#C5C907' }}>Unpublished</Typography>
                )}
            </>
        )
    },
    // { header: 'No of Surveyor', accessor: 'totalSurveyor' },
    // {
    //     header: 'Target',
    //     accessor: 'target',
    //     content: (item: any) => (
    //         <input
    //             disabled={disabled}
    //             style={{
    //                 width: '50%',
    //                 textAlign: 'center',
    //                 padding: ' 2px 5px',
    //                 border: 0,
    //                 backgroundColor: 'lightgray',
    //                 borderRadius: '5px'
    //             }}
    //             onChange={(e) => setTargetOnChange({ e, projectForms, item, dispatch })}
    //             onBlur={(e) => setTargetOnBlur({ e, item, snackbarMsg })}
    //             value={item.target}
    //         />
    //     )
    // },
    { header: 'Total Submission', accessor: 'totalSubmission' }
];
