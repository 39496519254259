import { RemoveCircleOutline } from '@mui/icons-material';
import DoneIcon from '@mui/icons-material/Done';
import { Button, Grid } from '@mui/material';
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useState } from 'react';
import { CircleMarker, MapContainer, Polyline, TileLayer, useMapEvents, Pane, useMap } from 'react-leaflet';
import SearchBar from './SearchBar';
import * as turf from '@turf/turf';
import useSnackbar from 'hooks/useSnackbar';

const LineMap = ({
    setFinalPolyline,
    setPolygonList,
    setChangeDetected,
    polygonList = [],
    initValues = [],
    search = true,
    editMain = true,
    locConfig = false
}) => {
    const defaultCenter = [23.685, 90.3563];
    const [selectedPosition, setSelectedPosition] = useState([]);
    const [polyline, setPolyline] = useState([]);
    const [radioSelected, setRadioSelected] = useState(0);
    const [areaComplete, setAreaComplete] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [radioCount, setRadioCount] = useState([0]);
    const [areaPolygon, setAreaPolygon] = useState(null);
    const [center, setCenter] = useState(initValues.length > 0 ? initValues[0] : defaultCenter);
    const [firstRender, setFirstRender] = useState(true);
    const [polygonSync, setPolygonSync] = useState(true);

    // =========================== multi-polygon area ==============================
    const [currentOpenPolygon, setCurrentOpenPolygon] = useState(null);
    const [polygonTracker, setPolygonTracker] = useState({});
    const [selectedArea, setSelectedArea] = useState(null);

    // =========================== multi-polygon area ==============================

    const snackbarMsg = useSnackbar();
    const fillBlueOptions = { fillColor: 'blue' };
    const fillGreenOptions = { fillColor: 'green' };

    const isPointInsidePolygon = (point, polygon) => {
        const [longitude, latitude] = point;
        const pointToCheck = turf.point([longitude, latitude]);
        try {
            return turf.booleanPointInPolygon(pointToCheck, polygon);
        } catch (e) {
            snackbarMsg('Select a valid Polygon area.', 'error');
        }
    };

    useEffect(() => {
        setPolygonSync(false);
        if (initValues.length > 0) {
            let selectedPositionBuilder = [];
            let radioCountBuilder = [];
            initValues.forEach((item, index) => {
                selectedPositionBuilder.push({ lat: item[0], long: item[1] });
                radioCountBuilder.push(index);
            });

            setPolyline(initValues);
            setSelectedPosition(selectedPositionBuilder);
            setRadioCount(radioCountBuilder);
            setCenter(initValues[0]);
            setAreaPolygon(turf.polygon([initValues]));

            if (initValues.length > 2) {
                setAreaComplete(true);
            }
            if (initValues[0][0] === initValues[initValues.length - 1][0] && initValues[0][1] === initValues[initValues.length - 1][1]) {
                setCompleted(true);
            }
        } else {
            if (locConfig) {
                setPolyline(initValues);
                setSelectedPosition([]);
                setRadioCount([0]);
                setCenter(defaultCenter);
            }

            setAreaPolygon(null);
        }
        if (polygonList.length > 0) {
            setCurrentOpenPolygon(polygonList.length);
            let tracker = {};
            for (let i = 0; i < polygonList.length; i++) {
                tracker[i] = {
                    completed: true,
                    areaCompleteOption: false
                };
            }
            setPolygonTracker(tracker);
            setSelectedArea(polygonList.length);
        } else {
            setCurrentOpenPolygon(null);
            setPolygonTracker({});
            setSelectedArea(null);
            setFirstRender(true);
        }
    }, [initValues]);

    useEffect(() => {
        console.log('**********************************************');
        console.log('currentOpenPolygon ===>> ', currentOpenPolygon);
        console.log('polygonList ===>> ', polygonList);
        console.log('polygonTracker ===>> ', polygonTracker);
        console.log('selectedArea ===>> ', selectedArea);
        console.log('**********************************************');
    }, [polygonTracker]);

    useEffect(() => {
        console.log('=========== polyline =================', polyline.length);
        console.log('selectedPosition ===>>> ', selectedPosition);
        console.log('polyline ===>>> ', polyline);
        console.log('radioSelected ===>>> ', radioSelected);
        console.log('radioCount ===>>> ', radioCount);
        console.log('areaComplete ===>>> ', areaComplete);
        console.log('completed ===>>> ', completed);
        console.log('center ===>>> ', center);
        if (polyline.length > 2) {
            setAreaComplete(true);
        } else {
            setAreaComplete(false);
        }
        if (!radioCount.includes(radioSelected)) {
            setRadioSelected(radioCount[radioCount.length - 1]);
        }
        setFinalPolyline(polyline);
    }, [polyline]);

    useEffect(() => {
        if (completed && !radioCount.includes(polyline.length - 1)) {
            setRadioCount([...radioCount, polyline.length - 1]);
        }
    }, [completed]);

    useEffect(() => {
        console.log('polygonList ===>>> ', polygonList);
        if (!polygonSync) {
            setPolygonSync(true);
            if (polygonList.length > 0) {
                setCurrentOpenPolygon(polygonList.length - 1);
                let tracker = {};
                let i = 0;
                for (; i < polygonList.length - 1; i++) {
                    tracker[i] = {
                        completed: true,
                        areaCompleteOption: false
                    };
                }
                tracker[i] = {
                    completed: false,
                    areaCompleteOption: false
                };
                setPolygonTracker(tracker);
                setSelectedArea(polygonList.length - 1);
            } else {
                setCurrentOpenPolygon(null);
                setPolygonTracker({});
                setSelectedArea(null);
                setFirstRender(true);
            }
        }
    }, [polygonList]);

    useEffect(() => {
        console.log('currentOpenPolygon ===>>> ', currentOpenPolygon);
    }, [currentOpenPolygon]);

    useEffect(() => {
        console.log('selectedArea ===>>> ', selectedArea);
        console.log('currentOpenPolygon ===>>> ', currentOpenPolygon);
        if (selectedArea !== null && selectedArea != currentOpenPolygon && setPolygonList) {
            if (polygonList[currentOpenPolygon].length > 2) {
                completeInnerArea();
            } else if (polygonList[currentOpenPolygon].length > 0) {
                const tempPolygonList = [...polygonList];
                tempPolygonList[currentOpenPolygon] = [];
                setPolygonList(tempPolygonList);
                setPolygonTracker({
                    ...polygonTracker,
                    [currentOpenPolygon]: {
                        completed: false,
                        areaCompleteOption: false
                    }
                });
            }
        }
    }, [selectedArea]);

    const setMapBoundsToFitPolygon = (map) => {
        if (polyline.length === 0) {
            return;
        }

        // Calculate bounds of the polygon
        const bounds = L.latLngBounds(polyline);

        // Calculate padding to ensure the polygon is not at the edge of the map
        const paddingOptions = { padding: [50, 50] };

        // Fit the bounds within the map and set an appropriate zoom level
        map.fitBounds(bounds, paddingOptions);
    };

    const MapWrapper = () => {
        const map = useMap();
        setFirstRender(false);

        // Call this function whenever the polygon points change
        setMapBoundsToFitPolygon(map);

        return null; // Return null to prevent rendering anything in the map
    };

    const addPoint = (lat, long) => {
        if (completed) {
            return;
        }
        const tempSelected = [...selectedPosition, { lat, long }];
        setSelectedPosition(tempSelected);

        const tempPolyline = [...polyline, [lat, long]];
        setCenter([lat, long]);
        setPolyline(tempPolyline);

        const tempRadio = [...radioCount, radioCount.length];
        setRadioCount(tempRadio);
    };

    const removePoint = () => {
        const tempSelected = [...selectedPosition];
        tempSelected.pop();
        setSelectedPosition(tempSelected);

        const tempPolyline = [...polyline];
        tempPolyline.pop();
        setPolyline(tempPolyline);
        setCenter(tempPolyline[tempPolyline.length - 1]);

        const tempRadio = [...radioCount];
        tempRadio.pop();
        if (tempRadio.length > 0) {
            setRadioCount(tempRadio);
        }

        if (completed) {
            setCompleted(false);
        }
    };

    const completeArea = () => {
        setPolyline([...polyline, polyline[0]]);
        setSelectedPosition([...selectedPosition, { lat: polyline[0][0], long: polyline[0][1] }]);
        setCompleted(true);
    };

    const addInnerPoint = (point) => {
        // get the current open area to add a point to
        // if it is the first point then current open area in empty/null
        if (!isPointInsidePolygon(point, areaPolygon) || !setPolygonList) {
            return;
        }
        if (setChangeDetected) {
            setChangeDetected(true);
        }
        if (currentOpenPolygon === null) {
            setCurrentOpenPolygon(0);
            setPolygonList([[point]]);
            setPolygonTracker({
                0: {
                    completed: false,
                    areaCompleteOption: false
                }
            });
            setSelectedArea(0);
        } else {
            const tempPolygonList = [...polygonList];
            tempPolygonList[currentOpenPolygon].push(point);
            setPolygonList(tempPolygonList);
            setPolygonTracker({
                ...polygonTracker,
                [currentOpenPolygon]: {
                    completed: false,
                    areaCompleteOption: tempPolygonList[currentOpenPolygon].length > 2 ? true : false
                }
            });
            setSelectedArea(currentOpenPolygon);
        }
    };

    const removeInnerPoint = () => {
        if (!setPolygonList) {
            return;
        }
        if (setChangeDetected) {
            setChangeDetected(true);
        }
        const tempPolygonList = [...polygonList];
        tempPolygonList[selectedArea].pop();
        setPolygonList(tempPolygonList);
        setPolygonTracker({
            ...polygonTracker,
            [selectedArea]: {
                completed: false,
                areaCompleteOption: tempPolygonList[selectedArea].length > 2 ? true : false
            }
        });
        setCurrentOpenPolygon(selectedArea);
    };

    const completeInnerArea = () => {
        if (!setPolygonList) {
            return;
        }
        const tempPolygonList = [...polygonList];
        tempPolygonList[currentOpenPolygon].push(tempPolygonList[currentOpenPolygon][0]);
        tempPolygonList.push([]);
        setPolygonList(tempPolygonList);
        setPolygonTracker({
            ...polygonTracker,
            [currentOpenPolygon]: {
                completed: true,
                areaCompleteOption: false
            }
        });
        console.log('currentOpenPolygon ===>> ', currentOpenPolygon);

        setCurrentOpenPolygon(tempPolygonList.length - 1);
    };

    const Polylines = () => {
        const map = useMapEvents({
            click(e) {
                if (!locConfig) {
                    addPoint(e.latlng.lat, e.latlng.lng);
                }
            }
        });

        return polyline ? (
            <>
                {polyline.map((p, index) => p[0] !== '' && p[1] !== '' && <CircleMarker key={index} center={[p[0], p[1]]}></CircleMarker>)}
                <Polyline pathOptions={fillBlueOptions} positions={polyline} />
            </>
        ) : null;
    };

    const MultiPolylines = () => {
        const map = useMapEvents({
            click(e) {
                const point = [e.latlng.lat, e.latlng.lng]; // Example point to check
                if (locConfig && isPointInsidePolygon(point, areaPolygon)) {
                    let selectedPolygonIndex = null;
                    polygonList.map((polygon, index) => {
                        if (polygon.length > 0 && index in polygonTracker && polygonTracker[index].completed) {
                            selectedPolygonIndex = isPointInsidePolygon(point, turf.polygon([polygon])) ? index : selectedPolygonIndex;
                        }
                    });
                    console.log('selectedPolygonIndex ===>> ', selectedPolygonIndex);
                    console.log('selectedArea ===>> ', selectedArea);
                    console.log('polygonTracker ===>> ', polygonTracker);
                    console.log('-----------------------------------------------');
                    if (
                        selectedPolygonIndex !== selectedArea &&
                        polygonTracker[selectedArea].completeArea &&
                        !polygonTracker[selectedArea].completed
                    ) {
                        completeInnerArea();
                    }
                    setSelectedArea(selectedPolygonIndex);
                    if (selectedPolygonIndex === null) {
                        addInnerPoint(point);
                    } else {
                        console.log('selectedPolygonIndex ==> ', selectedPolygonIndex);
                    }
                }
            }
        });

        return polygonList ? (
            <>
                {polygonList.map((polygon, polygonIndex) => {
                    return polygon ? (
                        <>
                            {polygon.map(
                                (p, index) =>
                                    p[0] !== '' &&
                                    p[1] !== '' && (
                                        <>
                                            {selectedArea === polygonIndex ? (
                                                <CircleMarker
                                                    pathOptions={{ color: 'red' }}
                                                    key={`${polygonIndex}_${index}`}
                                                    center={[p[0], p[1]]}
                                                ></CircleMarker>
                                            ) : (
                                                <CircleMarker
                                                    pathOptions={{ color: 'green' }}
                                                    key={`${polygonIndex}_${index}`}
                                                    center={[p[0], p[1]]}
                                                ></CircleMarker>
                                            )}
                                        </>
                                    )
                            )}
                            {selectedArea === polygonIndex ? (
                                <Pane name="yellow-rectangle" style={{ zIndex: 499 }}>
                                    <Polyline pathOptions={{ color: 'red' }} positions={polygon} key={polygonIndex} />
                                </Pane>
                            ) : (
                                <Polyline pathOptions={{ color: 'green' }} positions={polygon} key={polygonIndex} />
                            )}
                        </>
                    ) : null;
                })}
            </>
        ) : null;
    };

    return (
        <Grid container direction="row" style={{ border: '1px solid rgb(0,0,0,0.1)', padding: '40px' }}>
            <Grid item xs={12}>
                {editMain && polyline?.length > 0 && (
                    <Grid container style={{ padding: '10px', marginBottom: '10px' }}>
                        <Grid>
                            <Button sx={{ float: 'right' }} size="small" onClick={removePoint} variant="contained" color="error">
                                <RemoveCircleOutline />
                                Remove Last Point
                            </Button>
                        </Grid>
                        {areaComplete && !completed && (
                            <Grid style={{ marginLeft: '10px' }}>
                                <Button sx={{ float: 'right' }} size="small" onClick={completeArea} variant="contained" color="success">
                                    <DoneIcon />
                                    Complete
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                )}
                {!editMain && polygonList && polygonList?.length > 0 && selectedArea !== null && polygonList[selectedArea]?.length > 0 && (
                    <Grid container style={{ padding: '10px', marginBottom: '10px' }}>
                        <Grid>
                            <Button sx={{ float: 'right' }} size="small" onClick={removeInnerPoint} variant="contained" color="error">
                                <RemoveCircleOutline />
                                Remove Last Point from selected
                            </Button>
                        </Grid>
                        {polygonTracker &&
                            currentOpenPolygon in polygonTracker &&
                            polygonTracker[currentOpenPolygon].areaCompleteOption &&
                            !polygonTracker[currentOpenPolygon].completed && (
                                <Grid style={{ marginLeft: '10px' }}>
                                    <Button
                                        sx={{ float: 'right' }}
                                        size="small"
                                        onClick={completeInnerArea}
                                        variant="contained"
                                        color="success"
                                    >
                                        <DoneIcon />
                                        Complete
                                    </Button>
                                </Grid>
                            )}
                    </Grid>
                )}
            </Grid>

            <Grid item xs={12}>
                <MapContainer center={center} zoom={13} scrollWheelZoom={false} style={{ height: '500px', width: '100%' }}>
                    {search && <SearchBar />}
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Polylines />
                    {locConfig && <MultiPolylines />}
                    {firstRender && <MapWrapper />}
                </MapContainer>
            </Grid>
        </Grid>
    );
};

export default LineMap;
