import { Checkbox, Grid, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

// project imports
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { checkNullInfo } from 'utils/Helpers';

// Props type
export type SelectColumnType = {
    header: string;
    accessor: string;
    content?: any;
};

type SelectTableProps = {
    columns: SelectColumnType[];
    rows: any[];
    count: number;
    page: number;
    rowsPerPage: number;
    setPage: (value: number) => void;
    setRowsPerPage: (value: number) => void;
    idField: string;
    selectedFields: number[];
    checkAll: boolean;
    changeCheckAll: (value: boolean) => void;
    checkUpdate: (id: number) => void;
    link?: string;
    linkID?: string;
    showSL?: boolean;
};

// ==============================|| TABLE - BASIC ||============================== //

export default function SelectTable({
    columns,
    rows,
    count,
    page,
    rowsPerPage,
    link,
    linkID,
    setPage,
    setRowsPerPage,
    idField,
    selectedFields,
    checkAll,
    changeCheckAll,
    checkUpdate,
    showSL
}: SelectTableProps) {
    const navigate = useNavigate();
    const classes = useStyle();
    console.log('select Table');
    const handleChangePage = (event: any, newPage: number) => {
        if (setPage) {
            setPage(newPage);
        }
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    const renderCell = (item: any, column: any) => {
        if (column.content) {
            return column.content(item);
        }
        return <Typography noWrap>{checkNullInfo(item[column.accessor])}</Typography>;
    };

    const pageStatus = () => {
        if (count && rowsPerPage && page) {
            let start = 1;
            let end = count;
            const totalPage = Math.ceil(count / rowsPerPage);
            if (page > 1) {
                start = (page - 1) * rowsPerPage + 1;
            }
            if (page < totalPage) {
                end = start + rowsPerPage - 1;
            }
            return `Showing ${start} to ${end} of ${count} results.`;
        }
        return '';
    };

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <MainCard content={false}>
                    <TableContainer>
                        <Table sx={{ minWidth: 350 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell component="th">
                                        <Checkbox checked={checkAll} onChange={() => changeCheckAll(!checkAll)} />
                                    </TableCell>
                                    {showSL && (
                                        <TableCell align="center">
                                            <Typography variant="subtitle1" noWrap>
                                                SL
                                            </Typography>
                                        </TableCell>
                                    )}
                                    {columns.map((column: any, index: number) => (
                                        <TableCell key={index} align="center">
                                            <Typography variant="subtitle1" noWrap>
                                                {column.header}
                                            </Typography>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.length ? (
                                    rows.map((row: any, i: number) => (
                                        <TableRow
                                            key={i}
                                            className={classes.rowsHover}
                                            onClick={() => (linkID ? navigate(`${link + row[linkID]}/`) : '')}
                                        >
                                            <TableCell>
                                                <Checkbox
                                                    checked={selectedFields.includes(row[idField])}
                                                    onChange={() => row[idField] && checkUpdate(row[idField])}
                                                />
                                            </TableCell>
                                            {showSL && (
                                                <TableCell align="center">
                                                    <Typography variant="subtitle1" noWrap>
                                                        {i + 1}
                                                    </Typography>
                                                </TableCell>
                                            )}
                                            {columns.map((column: SelectColumnType, k: number) => (
                                                <TableCell align="center" key={k}>
                                                    {renderCell(row, column)}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow className={classes.rowsHover}>
                                        <TableCell align="center" colSpan={columns.length}>
                                            <h3>No Data Found</h3>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {page && count && rowsPerPage ? (
                        <Grid container spacing={3} alignItems="center" direction="row-reverse" style={{ padding: '1.5%' }}>
                            <Grid item>
                                <Pagination
                                    count={Math.ceil(count / rowsPerPage)}
                                    page={page}
                                    onChange={handleChangePage}
                                    showFirstButton
                                    showLastButton
                                />
                            </Grid>
                            <Grid item>
                                {' '}
                                <p>{pageStatus()}</p>{' '}
                            </Grid>
                        </Grid>
                    ) : (
                        ''
                    )}
                </MainCard>
            </Grid>
        </Grid>
    );
}

const useStyle = makeStyles({
    rowsHover: {
        borderBottom: '1px solid #DDD',
        '&:hover': {
            backgroundColor: '#FFE'
        }
    }
});
