import { useEffect, useState } from 'react';

// material-ui
import { Button, Grid, Typography } from '@mui/material';

// project imports
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArticleIcon from '@mui/icons-material/Article';
import Wrapper from 'components/Card/Wrapper';
import BasicDataTable from 'components/Table/BasicDataTable';
import useSnackbar from 'hooks/useSnackbar';
import { gridSpacing } from 'store/constant';
import SubCard from 'ui-component/cards/SubCard';
import axiosServices from 'utils/axiosServices';
import { DraftsFormsData } from 'utils/demoData';
import { _deserailize } from 'utils/Deserialize';
import langString from 'utils/langString';
import { templateListURL } from 'utils/serverUrls';
import { DELETE, DOWNLOAD_XLS, DOWNLOAD_XML, getColumns } from './constant';
import FormsFilter from './Filter';
import { formsDelete, formsDownload } from './Service';
import UploadDialog from './UploadDialog';

const Forms = () => {
    const [deployeds, setDeployeds] = useState<any[]>([]);
    const [drafts, setDrafts] = useState<any[]>([]);
    const [creationDateDeployed, setCreationDateDeployed] = useState<Date | null>(null);
    const [nameDeployed, setNameDeployed] = useState('');
    const [creationDateDraft, setCreationDateDraft] = useState<Date | null>(null);
    const [nameDraft, setNameDraft] = useState('');
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [openUploadDialog, setOpenUploadDialog] = useState<boolean>(false);
    const [chooseFormType, setChooseFormType] = useState(0);
    const [isReplace, setIsReplace] = useState(false);
    const snackbarMsg = useSnackbar();

    const actionButton = async ({ value, item, refresh }: any) => {
        if (value === 'replace') {
            setIsReplace(true);
            setOpenUploadDialog(true);
        }
        if (value === DELETE) {
            await formsDelete({ item, refresh, snackbarMsg });
        }
        if (value === DOWNLOAD_XLS) {
            await formsDownload({ item, type: value, refresh, snackbarMsg });
        }
        if (value === DOWNLOAD_XML) {
            await formsDownload({ item, type: value, refresh, snackbarMsg });
        }
    };

    const init = async () => {
        try {
            const deployedResponse = await axiosServices.post(`${templateListURL}`);
            const deployedList = await _deserailize(deployedResponse.data);
            setDeployeds(deployedList);
            setCount(deployedResponse.data.meta.count);
            const draftsResponse = DraftsFormsData;
            setDrafts(draftsResponse);
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        init();
    }, [page, rowsPerPage]);

    return (
        <Wrapper
            title={langString('formdesignandupload')}
            icon={<ArticleIcon />}
            actionBtn={
                <Button
                    onClick={() => setOpenUploadDialog(true)}
                    variant="contained"
                    startIcon={<AddCircleOutlineIcon />}
                    sx={{ float: 'right' }}
                >
                    {' '}
                    Add New Form
                </Button>
            }
        >
            <SubCard>
                <Grid container spacing={gridSpacing} justifyContent="space-between" alignItems="center">
                    <Grid item xs={12} sm={4} alignContent="center">
                        <Typography variant="h3">
                            {langString('deployed')} ({deployeds.length})
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <FormsFilter
                            name={nameDeployed}
                            setName={(value: string) => {
                                setNameDeployed(value);
                                const filter = deployeds.filter((item) => item.title.toLowerCase() === value.toLowerCase());
                                setDeployeds(filter);
                            }}
                            creationDate={creationDateDeployed}
                            setCreationDate={(value: Date | null) => setCreationDateDeployed(value)}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={gridSpacing} marginY={1}>
                    <Grid item xs={12}>
                        <BasicDataTable
                            rows={deployeds}
                            columns={getColumns({ actionButton, refresh: init, snackbarMsg })}
                            count={count}
                            page={page}
                            setPage={setPage}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                        />
                    </Grid>
                </Grid>
            </SubCard>
            <SubCard sx={{ marginTop: '3%' }}>
                <Grid container spacing={gridSpacing} alignItems="center">
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h3">
                            {langString('drafts')} ({drafts.length})
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <FormsFilter
                            name={nameDraft}
                            setName={(value: string) => setNameDraft(value)}
                            creationDate={creationDateDraft}
                            setCreationDate={(value: Date | null) => setCreationDateDraft(value)}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={gridSpacing} marginY={1}>
                    <Grid item xs={12}>
                        <BasicDataTable rows={[]} columns={getColumns({ actionButton })} />
                    </Grid>
                </Grid>
                <UploadDialog
                    open={openUploadDialog}
                    setOpen={setOpenUploadDialog}
                    setChooseFormType={setChooseFormType}
                    chooseFormType={chooseFormType}
                    refresh={init}
                    isReplace={isReplace}
                />
            </SubCard>
        </Wrapper>
    );
};

export default Forms;
