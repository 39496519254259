import { ProjectType } from 'views/Projects/Details/Constants';

// action type
export const ACTIVE_PROJECTS = 'project/active';
export const ARCHIVED_PROJECTS = 'project/archived';
export const CURRENT_PROJECT = 'project/current';
export const CURRENT_TAB = 'project/tab';

// type of action

type TActiveProjects = typeof ACTIVE_PROJECTS;
type TArchivedProjects = typeof ARCHIVED_PROJECTS;
type TCurrentProject = typeof CURRENT_PROJECT;
type TCurrentTab = typeof CURRENT_TAB;

type TActionTypes = TActiveProjects | TArchivedProjects | TCurrentProject | TCurrentTab;

// action - state management
interface IProjects {
    activeProjects?: ProjectType[] | null;
    archivedProjects?: ProjectType[] | null;
    currentProject?: ProjectType | null;
    currentTab: number;
}

const initialState: IProjects = {
    activeProjects: undefined,
    archivedProjects: undefined,
    currentProject: undefined,
    currentTab: 0
};
// ==============================|| Projects REDUCER ||============================== //

export interface IProjectsReducerActionProps {
    type: TActionTypes;
    payload?: IProjects;
}

const projectsReducer = (state = initialState, action: IProjectsReducerActionProps) => {
    switch (action.type) {
        case ACTIVE_PROJECTS: {
            return {
                ...state,
                ...action.payload
            };
        }
        case ARCHIVED_PROJECTS: {
            return {
                ...state,
                ...action.payload
            };
        }
        case CURRENT_PROJECT: {
            return {
                ...state,
                ...action.payload
            };
        }
        case CURRENT_TAB: {
            return {
                ...state,
                ...action.payload
            };
        }
        default: {
            return state;
        }
    }
};

export default projectsReducer;

// get project list
export const setAllActiveProjectsList = (activeProjects: ProjectType[] | null) => ({
    type: ACTIVE_PROJECTS,
    payload: { activeProjects }
});

export const setAllArchivedProjectsList = (archivedProjects: ProjectType[] | null) => ({
    type: ARCHIVED_PROJECTS,
    payload: { archivedProjects }
});

export const setCurrentProject = (project: ProjectType | null) => ({
    type: CURRENT_PROJECT,
    payload: { currentProject: project }
});

export const setCurrentTab = (tabIndex: string | number | null) => ({
    type: CURRENT_TAB,
    payload: { currentTab: tabIndex }
});
