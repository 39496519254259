import { ParentItem } from 'views/MasterData/Constants';
// action type
export const CATEGORY_PARENT_ADD = 'category/parent_add';

// type of action

type add_parent = typeof CATEGORY_PARENT_ADD;

type ParentActionTypes = add_parent;

// action - state management

interface Items {
    parents: ParentItem[];
}

const initialState: Items = {
    parents: []
};
// ==============================|| Projects REDUCER ||============================== //

export interface IProjectsReducerActionProps {
    type: ParentActionTypes;
    payload?: ParentItem | ParentItem[];
}

const parentItemReducer = (state = initialState, action: IProjectsReducerActionProps) => {
    switch (action.type) {
        case CATEGORY_PARENT_ADD: {
            console.log(action.payload);
            return {
                ...state,
                parents: action.payload
            };
        }

        // case CATEGORY_DELETE: {
        //     state.categories = state.categories.filter((item) => item.categoryValue !== action.payload?.categoryValue);
        //     return {
        //         ...state
        //     };
        // }
        default: {
            return state;
        }
    }
};

export default parentItemReducer;

export const addParent = (cat: ParentItem[] | null) => ({
    type: CATEGORY_PARENT_ADD,
    payload: cat
});

// export const deleteCategory = (cat: Category | null) => ({
//     type: CATEGORY_DELETE,
//     payload: cat
// });
