// type action
export const USERS = 'permission/users';

const initialState = {
    users: undefined,
};

export const permissionReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case USERS:
            return {
                ...state,
                users: action.payload,
            };

        default:
            return state;
    }
};

// action creator
export const usersAction = (payload: any) => ({
    type: USERS,
    payload
});





