import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import _ from 'lodash';

export function dateTimeDuration(date: string) {
    const dateTime = dayjs(date);
    return `Hour: ${dateTime.format('h')}  Minute: ${dateTime.minute()} ${dateTime.format('A')}`;
}

export function isPrevDate(date: string) {
    dayjs.extend(relativeTime);
    return dayjs().isBefore(dayjs(date));
}

export function dd_mm_yyyy(today: any): any {
    if (today == '' || today === null || today === undefined) return '';

    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();

    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;

    today = `${dd}-${mm}-${yyyy}`;
    return today;
}

export function yyyy_mm_dd(today: any): any {
    console.log('today: ', today);
    if (today == '' || today === null || today === undefined) return '';

    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();

    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;

    today = `${yyyy}-${mm}-${dd}`;
    return today;
}

export function dateFormatter(dateString: string) {
    const date = new Date(dateString);

    // Format the date part (YYYY-MM-DD)
    const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(
        2,
        '0'
    )}`;

    // Format the time part (h:mm A)
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedTime = `${hours % 12 || 12}:${String(minutes).padStart(2, '0')} ${ampm}`;

    // Combine date and time with a semicolon
    return `${formattedDate}; ${formattedTime}`;
}
