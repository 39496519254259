// action type
export const FORM_LIST = 'form/list';
export const ALL_FORM_SUMMARY = 'form/allFormSummary';
export const CURRENT_PROPERTIES = 'form/currentProperties';
export const IMPORT_FORM = 'form/importForm';
export const FORM_NAME = 'form/importForm/formName';
export const FORMS = 'form/importForm/forms';
export const SINGLE_FORM = 'form/importForm/singleForm';

export const USER_LIST = 'form/userList';
export const CURRENT_SUMMARY = 'form/currentSummary';
export const CURRENT_USER = 'form/currentUser';

export const USER_TABLE_DATA = 'form/userTableData';
export const USER_SUMMARY = 'form/userSummary';
export const USER_TABLE_DATA_PARTIAL = 'form/userTableData/partial';

export const IMPORT_FORMS = 'form/importForms/forms';
export const ORGANIZATIONS = 'form/importForms/organizations';
export const ROLES = 'form/importForms/roles';
export const USERS = 'form/importForms/users';

export const CURRENT_FORM = 'form/importForms/current/form';
export const RESET_CURRENT_FORM = 'form/importForms/current/ResetForm';
export const CURRENT_FORM_NAME = 'form/importForms/current/formName';
export const CURRENT_ORGANIZATIONS = 'form/importForms/current/organizations';
export const CURRENT_ROLES = 'form/importForms/current/roles';
export const CURRENT_USERS = 'form/importForms/current/users';

// add user
export const ADD_USER_FILTER = 'form/addUser/filter';
export const ADD_USER_CURRENT_FILTER = 'form/addUser/filter/current';
export const ADD_USER_USERS = 'form/addUser/users';
export const ADD_USER_CURRENT_USER = 'form/addUser/currentUser';

// ==============================|| type of action ||============================== //
type TFormList = typeof FORM_LIST;
type TCurrentProperties = typeof CURRENT_PROPERTIES;
type TAllFormSummary = typeof ALL_FORM_SUMMARY;
type TImportForm = typeof IMPORT_FORM;
type TSingleForm = typeof SINGLE_FORM;

type TUserList = typeof USER_LIST;
type TCurrentSummary = typeof CURRENT_SUMMARY;
type TCurrentUser = typeof CURRENT_USER;

type TUserTableData = typeof USER_TABLE_DATA;
type TUserSummary = typeof USER_SUMMARY;
type TUserTableDataPartial = typeof USER_TABLE_DATA_PARTIAL;

type TImportForms = typeof IMPORT_FORMS;
type TOrganizations = typeof ORGANIZATIONS;
type TRoles = typeof ROLES;
type TUsers = typeof USERS;

type TCurrentForm = typeof CURRENT_FORM;
type TResetCurrentForm = typeof RESET_CURRENT_FORM;
type TCurrentFormName = typeof CURRENT_FORM_NAME;
type TCurrentOrganizations = typeof CURRENT_ORGANIZATIONS;
type TCurrentRoles = typeof CURRENT_ROLES;
type TCurrentUsers = typeof CURRENT_USERS;

type TAddUserFilter = typeof ADD_USER_FILTER;
type TAddUserCurrentFilter = typeof ADD_USER_CURRENT_FILTER;
type TAddUserUsers = typeof ADD_USER_USERS;
type TAddUserCurrentUsers = typeof ADD_USER_CURRENT_USER;

type TActionTypes =
    | TFormList
    | TImportForm
    | TSingleForm
    | TCurrentProperties
    | TAllFormSummary
    | TUserList
    | TCurrentSummary
    | TCurrentUser
    | TUserTableData
    | TUserTableDataPartial
    | TImportForms
    | TOrganizations
    | TRoles
    | TUsers
    | TCurrentForm
    | TResetCurrentForm
    | TCurrentFormName
    | TCurrentOrganizations
    | TCurrentRoles
    | TCurrentUsers
    | TUserSummary
    | TAddUserFilter
    | TAddUserCurrentFilter
    | TAddUserUsers
    | TAddUserCurrentUsers;

// ==============================|| action - state management ||============================== //
interface IForm {
    list?: any[] | null;
    current?: {
        summary?: any | null;
        properties?: any | null;
    };
    importForm?: {
        forms?: any[] | null;
        organizations?: any[] | null;
        roles?: any[] | null;
        users?: any[] | null;
        currentState?: {
            formName?: string;
            form?: any | null;
            organizations?: any[] | null;
            roles?: any[] | null;
            users?: any[] | null;
        };
    };
    addUser: {
        filter: {
            organizations?: any[];
            roles?: any[];
            current?: {
                userName?: string;
                organizations?: any[];
                roles?: any[];
            };
        };
        users?: any[];
        currentUser?: any[];
    };

    allFormSummary?: any | null;

    userList?: any[] | null;
    currentUser?: any | null;

    userTableData?: {
        rows?: any[] | null;
        columns?: any[] | null;
        filteredColumns?: any[] | null;
    };

    userSummary?: any | null;
}

const initialState: IForm = {
    list: undefined,
    current: {
        summary: {},
        properties: {}
    },

    allFormSummary: undefined,
    importForm: {
        forms: [],
        organizations: [],
        roles: [],
        users: [],

        currentState: {
            formName: '',
            form: undefined,
            organizations: undefined,
            roles: undefined,
            users: undefined
        }
    },

    addUser: {
        filter: {
            organizations: [],
            roles: [],
            current: {
                userName: '',
                organizations: [],
                roles: []
            }
        },
        users: undefined,
        currentUser: []
    },

    userList: undefined,
    currentUser: undefined,

    userTableData: {
        rows: undefined,
        columns: undefined,
        filteredColumns: undefined
    },
    userSummary: undefined
};

// ==============================|| REDUCER ||============================== //

export interface IProjectsReducerActionProps {
    type: TActionTypes;
    payload?: IForm;
}

const formReducer = (state = initialState, action: IProjectsReducerActionProps) => {
    switch (action.type) {
        case FORM_LIST: {
            return {
                ...state,
                ...action.payload
            };
        }
        case CURRENT_PROPERTIES: {
            return {
                ...state,
                current: {
                    ...state.current,
                    ...action.payload
                }
            };
        }
        case ALL_FORM_SUMMARY: {
            return {
                ...state,
                ...action.payload
            };
        }
        case USER_LIST: {
            return {
                ...state,
                ...action.payload
            };
        }
        case CURRENT_SUMMARY: {
            return {
                ...state,
                current: {
                    ...state.current,
                    ...action.payload
                }
            };
        }
        case CURRENT_USER: {
            return {
                ...state,
                ...action.payload
            };
        }
        case USER_TABLE_DATA: {
            return {
                ...state,
                ...action.payload
            };
        }
        case USER_TABLE_DATA_PARTIAL: {
            return {
                ...state,
                userTableData: {
                    ...state.userTableData,
                    ...action.payload
                }
            };
        }
        case USER_SUMMARY: {
            return {
                ...state,
                ...action.payload
            };
        }
        case IMPORT_FORMS: {
            return {
                ...state,
                importForm: {
                    ...state.importForm,
                    forms: action.payload
                }
            };
        }

        case ORGANIZATIONS: {
            return {
                ...state,
                importForm: {
                    ...state.importForm,
                    organizations: action.payload
                }
            };
        }
        case ROLES: {
            return {
                ...state,
                importForm: {
                    ...state.importForm,
                    roles: action.payload
                }
            };
        }
        case USERS: {
            return {
                ...state,
                importForm: {
                    ...state.importForm,
                    users: action.payload
                }
            };
        }
        case CURRENT_FORM: {
            return {
                ...state,
                importForm: {
                    ...state.importForm,
                    currentState: {
                        ...state.importForm?.currentState,
                        form: action.payload
                    }
                }
            };
        }
        case SINGLE_FORM: {
            return {
                ...state,
                importForm: {
                    ...state.importForm,
                    currentState: { ...action.payload }
                }
            };
        }
        case RESET_CURRENT_FORM: {
            return {
                ...state,
                importForm: {
                    ...state.importForm,
                    currentState: {
                        formName: '',
                        form: undefined,
                        organizations: undefined,
                        roles: undefined,
                        users: undefined
                    }
                }
            };
        }
        case CURRENT_FORM_NAME: {
            return {
                ...state,
                importForm: {
                    ...state.importForm,
                    currentState: {
                        ...state.importForm?.currentState,
                        formName: action.payload
                    }
                }
            };
        }
        case CURRENT_ORGANIZATIONS: {
            return {
                ...state,
                importForm: {
                    ...state.importForm,
                    currentState: {
                        ...state.importForm?.currentState,
                        organizations: action.payload
                    }
                }
            };
        }
        case CURRENT_ROLES: {
            return {
                ...state,
                importForm: {
                    ...state.importForm,
                    currentState: {
                        ...state.importForm?.currentState,
                        roles: action.payload
                    }
                }
            };
        }
        case CURRENT_USERS: {
            return {
                ...state,
                importForm: {
                    ...state.importForm,
                    currentState: {
                        ...state.importForm?.currentState,
                        users: action.payload
                    }
                }
            };
        }
        case ADD_USER_FILTER: {
            return {
                ...state,
                addUser: {
                    ...state.addUser,
                    filter: {
                        ...state.addUser?.filter,
                        ...action.payload
                    }
                }
            };
        }
        case ADD_USER_CURRENT_FILTER: {
            return {
                ...state,
                addUser: {
                    ...state.addUser,
                    filter: {
                        ...state.addUser?.filter,
                        current: {
                            ...state.addUser?.filter?.current,
                            ...action.payload
                        }
                    }
                }
            };
        }
        case ADD_USER_USERS: {
            return {
                ...state,
                addUser: {
                    ...state.addUser,
                    users: action.payload
                }
            };
        }
        case ADD_USER_CURRENT_USER: {
            return {
                ...state,
                addUser: {
                    ...state.addUser,
                    currentUser: action.payload
                }
            };
        }
        default: {
            return state;
        }
    }
};

export default formReducer;

// ==============================|| action creator ||============================== //

// form
export const setFormList = (forms: any[] | null) => ({
    type: FORM_LIST,
    payload: { list: forms }
});

export const setCurrentForm = (properties: any | null) => ({
    type: CURRENT_PROPERTIES,
    payload: { properties }
});

export const singleForm = (currentForm: any) => ({
    type: SINGLE_FORM,
    payload: { ...currentForm }
});

export const setAllFormSummary = (allFormSummary: any | null) => ({
    type: ALL_FORM_SUMMARY,
    payload: { allFormSummary }
});

// user
export const setUserList = (userList: any[] | null) => ({
    type: USER_LIST,
    payload: { userList }
});

export const setSummary = (summary: any | null) => ({
    type: CURRENT_SUMMARY,
    payload: { summary }
});

export const setCurrentUser = (currentUser: any | null) => ({
    type: CURRENT_USER,
    payload: { currentUser }
});

// user data
export const setUserTableData = (userTableData: any | null) => ({
    type: USER_TABLE_DATA,
    payload: { userTableData }
});

// user data partial
export const setUserTableDataPartial = (filteredColumns: any | null) => ({
    type: USER_TABLE_DATA_PARTIAL,
    payload: { filteredColumns }
});

export const setUserSummary = (userSummary: any | null) => ({
    type: USER_SUMMARY,
    payload: { userSummary }
});

// Import forms
export const setImportFormList = (forms: any | null) => ({
    type: IMPORT_FORMS,
    payload: forms
});

export const setOrganizations = (organizations: any[] | null) => ({
    type: ORGANIZATIONS,
    payload: organizations
});

export const setRoles = (roles: any[] | null) => ({
    type: ROLES,
    payload: roles
});

export const setUsers = (users: any[] | null) => ({
    type: USERS,
    payload: users
});

export const setCurrentImportForm = (form: string) => ({
    type: CURRENT_FORM,
    payload: form
});
export const resetImportForm = () => ({
    type: RESET_CURRENT_FORM,
});

export const setCurrentFormName = (formName: string) => ({
    type: CURRENT_FORM_NAME,
    payload: formName
});

export const setCurrentOrganizations = (organizations: any[] | null) => ({
    type: CURRENT_ORGANIZATIONS,
    payload: organizations
});

export const setCurrentRoles = (roles: any[] | null) => ({
    type: CURRENT_ROLES,
    payload: roles
});

export const setCurrentUsers = (users: any[] | null) => ({
    type: CURRENT_USERS,
    payload: users
});

// add user

export const setUserFilterForAddUser = (filter: any) => ({
    type: ADD_USER_FILTER,
    payload: { ...filter }
});
export const setCurrentFilterInUserForAddUser = (current: any) => ({
    type: ADD_USER_CURRENT_FILTER,
    payload: { ...current }
});
export const setUsersForAddUser = (users: any) => ({
    type: ADD_USER_USERS,
    payload: users
});
export const setCurrentUserForAddUser = (currentUser: any) => ({
    type: ADD_USER_CURRENT_USER,
    payload: currentUser
});
