import lodash from 'lodash';
import { setCurrentForm, setUserList } from 'store/formsReducer';
import axiosServices from 'utils/axiosServices';
import { _deserailize } from 'utils/Deserialize';
import { errMsg } from 'utils/Helpers';
import { createManualUserTargetURL, getFormByFormIdURL, getUserListURL, updateFormsUserList } from 'utils/serverUrls';
import { isPositive } from '../Service';

export const setTargetOnChange = async ({ e, userList, item, dispatch }: any) => {
    const cloneRows = [...userList!];
    // eslint-disable-next-line array-callback-return
    cloneRows.map((row: any) => {
        if (row.id === item.id) {
            row.targetValue = isPositive(parseInt(e.target.value, 10)) ? parseInt(e.target.value, 10) : 0;
        }
    });

    dispatch(setUserList(cloneRows));
};

export const setTargetOnBlur = async ({ e, formId, item, snackbarMsg }: any) => {
    try {
        await axiosServices.post(createManualUserTargetURL, {
            targetSetting: {
                formId,
                targetValue: e.target.value,
                targetDate: new Date().toJSON().slice(0, 10)
            },
            users: [item.id]
        });
        snackbarMsg(`Target updated successfully`);
    } catch (error: any) {
        const msg = error.response.data.errors.map((ex: any) => ex.detail).join(', ');
        snackbarMsg(msg, 'error');
    }
};

export const updateStatus = async ({ row, formId, userList, dispatch, snackbarMsg }: any) => {
    try {
        const response = await axiosServices.put(updateFormsUserList, {
            formId,
            userId: row.id,
            isActive: row.isActive
        });
        if (response.status === 200) {
            const allRows = [...userList!];
            const currentRowIndex = lodash.findIndex(allRows, (o) => o.id === row?.id);
            if (currentRowIndex > -1) {
                allRows[currentRowIndex] = row;
            }
            dispatch(setUserList(allRows));
            let tag = '';
            if (row.isActive) {
                tag = 'activated';
            } else {
                tag = 'inactivated';
            }
            snackbarMsg(`Status ${tag} successfully`);
        }
    } catch (error: any) {
        const msg = error.response.data.errors.map((e: any) => e.detail).join(', ');
        snackbarMsg(msg, 'error');
    }
};

export const fetchFormsProfiles = async ({
    fromDate,
    toDate,
    formId,
    userName,
    isActive,
    currentPage,
    pageSize,
    snackbarMsg,
    dispatch
}: any) => {
    try {
        const params = {
            fromDate,
            toDate,
            formId,
            userName,
            isActive,
            currentPage,
            pageSize
        };
        const response = await axiosServices.post(`${getUserListURL}`, { ...params });
        const data = await _deserailize(response.data);
        return await new Promise((r, _) => r({ data, count: response?.data?.meta?.count }));
    } catch (ex) {
        snackbarMsg(errMsg(ex), 'error');
        dispatch(setUserList(null));
        return await new Promise((r, _) => r({ data: [], count: 0 }));
    }
};

export const fetchForm = async ({ dispatch, formId }: any) => {
    try {
        const formRes = await axiosServices.get(getFormByFormIdURL, { params: { formId } });
        const formData = await _deserailize(formRes.data);

        dispatch(setCurrentForm(formData));
    } catch (error) {
        dispatch(setCurrentForm(null));
    }
};
