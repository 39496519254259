import axiosFormServices from 'utils/axiosFormServices';
import axiosServices from 'utils/axiosServices';
import { errMsg } from 'utils/Helpers';
import { createJsonInitURL, formCloneURL, formsDeleteURL, formsDownloadURL } from 'utils/serverUrls';

export const formsDownload = ({ item, type, snackbarMsg }: any) => {
    axiosFormServices
        .post(`${formsDownloadURL}`, { id_string: item.idString, type }, { responseType: 'blob' })
        .then(({ data }) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', `${item.title.toLowerCase().split(' ').join('-')}.${type}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
        .catch((ex) => {
            snackbarMsg(errMsg(ex), 'error');
        });
};

export const formsDelete = async ({ item, refresh, snackbarMsg }: any) => {
    try {
        await axiosServices.post(`${formsDeleteURL}`, { formId: item.id });
        snackbarMsg('Form deleted successfully', 'success');
        refresh();
    } catch (ex) {
        snackbarMsg(errMsg(ex), 'error');
    }
};
export const formsClone = async ({ item, refresh, snackbarMsg }: any) => {
    console.log(item);

    try {
        const body = {
            id_string: item.idString,
            form_title: `${item.title}_clone_${new Date().getTime()}`
        };

        await axiosFormServices.post(`${formCloneURL}`, body);
        snackbarMsg('Form Clone successfully', 'success');
        refresh();
    } catch (ex) {
        snackbarMsg('Form Clone not successfully', 'error');
    }
};

export const formsBuildScratch = async ({ values, refresh, snackbarMsg }: any) => {

    try {
        const body = {
            title: `UI - ${values.title}`,
            description: values.description,
        };

        const res = await axiosServices.post(`${createJsonInitURL}`, body);
        snackbarMsg('Form Add successfully', 'success');
        refresh();
        return res.data;
    } catch (ex) {
        snackbarMsg('Form Add not successfully', 'error');
        return null;
    }
};

export default formsDownload;
