import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
// import { Deserializer } from 'jsonapi-serializer';
import { useSelector } from 'react-redux';
import { gridSpacing } from 'store/constant';
import axiosServices from 'utils/axiosServices';
import { CURRENT_PAGE } from 'utils/Constants';
import { _deserailize } from 'utils/Deserialize';
import langString from 'utils/langString';
import { organizationListURL, roleListURL } from 'utils/serverUrls';
import { ProjectType } from '../Constants';

type ProjectTeamsFilterPropsType = {
    organization: number;
    setOrganization: (value: number) => void;
    role: number;
    setRole: (value: number) => void;
    name: string;
    setName: (value: string) => void;
    project: ProjectType;
    filter: () => void;
};
export default function ProjectTeamsFilter({
    project,
    organization,
    setOrganization,
    role,
    setRole,
    name,
    setName,
    filter
}: ProjectTeamsFilterPropsType) {
    const [roles, setRoles] = useState<any[]>([]);
    const [organizationList, setOrganizationList] = useState<any[]>([]);
    const account = useSelector((state: any) => state.account);

    useEffect(() => {
        const init = async () => {
            const orgListResponse = await axiosServices.get(`${organizationListURL}`, {
                params: { orgId: account.user.organization.id, id: project.id, currentPage: CURRENT_PAGE, pageSize: 100 }
            });
            setOrganizationList(await _deserailize(orgListResponse.data));
        };
        if (account.user) init();
    }, [account.user]);

    useEffect(() => {
        console.log('organization: ', organization);
        (async () => {
            const roleResponse = await axiosServices.get(`${roleListURL}`, {
                params: { orgId: organization, currentPage: CURRENT_PAGE, pageSize: 100 }
            });
            setRoles(await _deserailize(roleResponse.data));
        })();
    }, [organization]);

    return (
        <>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} sm={3}>
                    <TextField
                        size="small"
                        id="outlined-select-organization"
                        select
                        fullWidth
                        label={langString('organization')}
                        value={organization}
                        onChange={(event: any) => setOrganization(event.target.value)}
                    >
                        <MenuItem value={0}>{langString('Select')}</MenuItem>
                        {organizationList &&
                            organizationList.map((option: any) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        size="small"
                        id="outlined-select-role"
                        select
                        fullWidth
                        label={langString('role')}
                        value={role}
                        onChange={(event: any) => setRole(event.target.value)}
                    >
                        <MenuItem value="0">{langString('Select')}</MenuItem>
                        {roles &&
                            roles.map((option: any) => (
                                <MenuItem key={option.id} value={JSON.stringify(option)}>
                                    {option.name}
                                </MenuItem>
                            ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <TextField
                        size="small"
                        id="outlined-select-name"
                        fullWidth
                        label={langString('username')}
                        value={name}
                        onChange={(event: any) => setName(event.target.value)}
                    />
                </Grid>
                {/* <Grid item xs={12} sm={3}>
                    <Button variant="contained" color="primary" onClick={filter}>
                        {langString('filter')}
                    </Button>
                </Grid> */}
            </Grid>
        </>
    );
}
