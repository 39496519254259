import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Button, FormControl, Grid, Stack, TextField } from '@mui/material';
import { gridSpacing } from 'store/constant';
import langString from 'utils/langString';

type FormsFilterPropsType = {
    name: string;
    setName: (value: string) => void;

    creationDate: Date | null;
    setCreationDate: (value: Date | null) => void;
};
export default function FormsFilter({ name, setName, creationDate, setCreationDate }: FormsFilterPropsType) {
    return (
        <Grid container alignItems="center" spacing={gridSpacing} justifyContent="space-between">
            <Grid item xs={12} sm={5}>
                <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={3}>
                            <DesktopDatePicker
                                label={langString('creationdate')}
                                inputFormat="yyyy/mm/dd"
                                value={creationDate}
                                onChange={(newValue: Date | null) => setCreationDate(newValue)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={5}>
                <FormControl fullWidth>
                    <TextField
                        size="medium"
                        id="outlined-name"
                        label={`${langString('name')} *`}
                        name="name"
                        type="text"
                        value={name}
                        onChange={(event: any) => setName(event.target.value)}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={2} textAlign="right">
                <Button variant="contained">Search</Button>
            </Grid>
        </Grid>
    );
}
