import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

// material-ui
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import FileCopyIcon from '@mui/icons-material/FileCopy';
import Wrapper from 'components/Card/Wrapper';
import useQuery from 'hooks/useQuery';
import { useDispatch, useSelector } from 'react-redux';
import { gridSpacing } from 'store/constant';
import { setCurrentProject, setCurrentTab } from 'store/projectReducer';
import { TRootState } from 'store/reducer';
import axiosServices from 'utils/axiosServices';
import { _deserailize } from 'utils/Deserialize';
import { projectGetURL } from 'utils/serverUrls';
import { tabsOption, tabStyles } from './Constants';
import DataSheet from './DataSheet';
import ProjectDescription from './Description';
import ProjectForms from './Forms';
import ProjectModules from './Modules';
import ProjectReports from './Reports';
import ProjectTeams from './Teams';
import LocationConfiguration from 'views/AreaManagement/LocationConfiguration';

// tabs panel
function TabPanel(props: { children: React.ReactElement; value: number; index: number }) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

// ==============================|| ProjectDetails  ||============================== //

const ProjectDetails = () => {
    const theme = useTheme();
    const { id: projectId } = useParams();
    const { tabIndex } = useQuery('tabIndex');
    const { currentProject, currentTab } = useSelector((state: TRootState) => state.projects);
    const dispatch = useDispatch();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        dispatch(setCurrentTab(newValue));
    };

    useEffect(() => {
        if (tabIndex) {
            dispatch(setCurrentTab(parseInt(tabIndex, 10)));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabIndex, projectId]);

    const init = async () => {
        const response = await axiosServices.get(`${projectGetURL}`, { params: { id: projectId } });
        dispatch(setCurrentProject(await _deserailize(response.data)));
    };

    useEffect(() => {
        init();
    }, []);

    return (
        <Wrapper title={currentProject?.name || 'Project Title'} icon={<FileCopyIcon />} backLink="/">
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <Tabs
                        value={currentTab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChange}
                        aria-label="simple tabs example"
                        variant="scrollable"
                        sx={tabStyles(theme)}
                    >
                        {tabsOption.map((tab: any, index: any) => (
                            <Tab
                                key={index}
                                component={Link}
                                to={`/project/${projectId}?tabIndex=${index}`}
                                icon={tab.icon}
                                label={tab.label}
                                {...a11yProps(index)}
                            />
                        ))}
                    </Tabs>
                    {/* <TabPanel value={currentTab} index={0}>
                        {projectId ? (
                            <ProjectDescription project={currentProject} refresh={() => init()} />
                        ) : (
                            <Typography>No Description</Typography>
                        )}
                    </TabPanel> */}
                    <TabPanel value={currentTab} index={0}>
                        {projectId ? <ProjectTeams /> : <Typography>No Teams</Typography>}
                    </TabPanel>
                    <TabPanel value={currentTab} index={1}>
                        {projectId ? <ProjectForms projectID={parseInt(projectId, 10)} /> : <Typography>No Forms</Typography>}
                    </TabPanel>
                    <TabPanel value={currentTab} index={2}>
                        {projectId ? <LocationConfiguration /> : <Typography>No Location Configuration to show</Typography>}
                    </TabPanel>
                    {/* <TabPanel value={currentTab} index={3}>
                        {projectId ? <DataSheet projectID={parseInt(projectId, 10)} /> : <Typography>No Forms</Typography>}
                    </TabPanel>
                    <TabPanel value={currentTab} index={4}>
                        {projectId ? <ProjectReports projectID={parseInt(projectId, 10)} /> : <Typography>No Project Reports</Typography>}
                    </TabPanel>
                    <TabPanel value={currentTab} index={5}>
                        {projectId ? <ProjectModules projectID={parseInt(projectId, 10)} /> : <Typography>No Project Module</Typography>}
                    </TabPanel> */}
                </Grid>
            </Grid>
        </Wrapper>
    );
};

export default ProjectDetails;
