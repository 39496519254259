import { Autocomplete, Box, Button, Checkbox, FormControl, FormHelperText, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import useSnackbar from 'hooks/useSnackbar';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CURRENT_PAGE } from 'utils/Constants';
import { _deserailize } from 'utils/Deserialize';
import { getToken } from 'utils/Helpers';
import { checkedIcon, icon } from 'views/Projects/Constants';
import { formsBuildScratch } from './Service';

// export const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// export const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function BuildFromScratch({ handleClose, refresh, handleCloseModal }: any) {
    const snackbarMsg = useSnackbar();
    const navigate = useNavigate();
    const submitData = async (values: any) => {
        try {
            const res = await formsBuildScratch({ values, refresh, snackbarMsg });
            refresh();
            handleCloseModal();
            const aTag = document.createElement('a');
            aTag.href = `${process.env.REACT_APP_FRONT_DESIGNER_URL}?id=${res.id}&token=${getToken('token')}`;
            aTag.target = '_blank';
            aTag.click();
            aTag.remove();

        } catch (error: any) {
            snackbarMsg(error.message, 'error');
        }

    };

    const formik = useFormik({
        initialValues: {
            title: '',
            description: '',
            // section: [],
            // country: []
        },
        onSubmit: (values) => submitData(values)
    });



    return (
        <form onSubmit={formik.handleSubmit} autoComplete="off">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="h5">Form Name*</Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        onChange={formik.handleChange}
                        name="title"
                        value={formik.values.title}
                        onBlur={formik.handleBlur}
                        error={formik.touched.title && Boolean(formik.errors.title)}
                    />
                    <FormHelperText style={{ color: 'red' }}>
                        {formik.errors.title && formik.touched.title && formik.errors.title}
                    </FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">Form Description</Typography>
                    <TextField
                        multiline
                        rows={4}
                        fullWidth
                        type="text"
                        variant="outlined"
                        placeholder="Form Description"
                        onChange={formik.handleChange}
                        name="description"
                        value={formik.values.description}
                        onBlur={formik.handleBlur}
                    />
                </Grid>
                {/* <Grid item xs={6}>
                    <FormControl fullWidth>
                        <Typography variant="h5">Section</Typography>
                        <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            options={[]}
                            onChange={(e, value) => formik.setFieldValue('section', value)}
                            value={formik.values.section}
                            disableCloseOnSelect
                            getOptionLabel={(option: any) => option.name}
                            renderOption={(props, option: any, { selected }) => (
                                <li {...props}>
                                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                    {option.name}
                                </li>
                            )}
                            renderInput={(params: any) => (
                                <TextField
                                    {...params}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.section && Boolean(formik.errors.section)}
                                />
                            )}
                        />

                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        <Typography variant="h5">Country</Typography>
                        <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            options={[]}
                            onChange={(e, value) => formik.setFieldValue('country', value)}
                            value={formik.values.country}
                            disableCloseOnSelect
                            getOptionLabel={(option: any) => option.name}
                            renderOption={(props, option: any, { selected }) => (
                                <li {...props}>
                                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                    {option.name}
                                </li>
                            )}
                            renderInput={(params: any) => (
                                <TextField
                                    {...params}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.country && Boolean(formik.errors.country)}
                                />
                            )}
                        />

                    </FormControl>
                </Grid> */}
                <Grid item xs={12} marginTop={2}>
                    <Box textAlign='center'>
                        <Button variant="contained" color="error" onClick={handleClose}>
                            {' '}
                            Cancel{' '}
                        </Button>

                        <Button sx={{ marginLeft: 2 }} variant="contained" type="submit">
                            {' '}
                            Submit{' '}
                        </Button>

                    </Box>
                </Grid>
            </Grid>
        </form>
    );
}
