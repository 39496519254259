import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
// project imports
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { checkNullInfo } from 'utils/Helpers';
import langString from 'utils/langString';

// Props type
export type ColumnType = {
    header: string;
    accessor: string;
    content?: any;
};

type ActionTableProps = {
    columns: any[];
    rows: any[];
    count: number;
    page: number;
    rowsPerPage: number;
    setPage: (value: number) => void;
    setRowsPerPage: (value: number) => void;
    idField?: string;
    statusField?: string;
    copyRow?: (id: number) => void;
    updateStatus?: (row: any) => void;
    showSL?: boolean;
};

// ==============================|| TABLE - BASIC ||============================== //

export default function FormUserTable({
    columns,
    rows,
    count,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    idField,
    statusField,
    updateStatus,
    copyRow,
    showSL
}: ActionTableProps) {
    const classes = useStyle();

    const handleChangePage = (event: React.ChangeEvent<unknown>, pageSize: number) => {
        setPage(pageSize);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    const renderCell = (item: any, column: any) => {
        if (column.content) {
            return column.content(item);
        }
        return <Typography noWrap>{checkNullInfo(item[column.accessor])}</Typography>;
    };

    const pageStatus = () => {
        if (count && rowsPerPage && page) {
            let start = 1;
            let end = count;
            const totalPage = Math.ceil(count / rowsPerPage);
            if (page > 1) {
                start = (page - 1) * rowsPerPage + 1;
            }
            if (page < totalPage) {
                end = start + rowsPerPage - 1;
            }
            return `Showing ${start} to ${end} of ${count} results.`;
        }
        return '';
    }

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <MainCard content={false}>
                    <TableContainer>
                        <Table sx={{ minWidth: 350 }} aria-label="action table">
                            <TableHead>
                                <TableRow>
                                    {showSL && (
                                        <TableCell align="center">
                                            <Typography variant="subtitle1" noWrap>
                                                SL
                                            </Typography>
                                        </TableCell>
                                    )}
                                    {columns.map((column: any, index: number) => (
                                        <TableCell key={index} align="center">
                                            <Typography variant="subtitle1" noWrap>
                                                {column.header}
                                            </Typography>
                                        </TableCell>
                                    ))}
                                    {idField && copyRow && (
                                        <TableCell align="center">
                                            <Typography variant="subtitle1" noWrap>
                                                {langString('copy')}
                                            </Typography>
                                        </TableCell>
                                    )}
                                    {statusField && idField && updateStatus && (
                                        <TableCell align="center">
                                            <Typography variant="subtitle1" noWrap>
                                                {langString('changestatus')}
                                            </Typography>
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.length ? (
                                    rows.map((row: any, i: number) => (
                                        <TableRow key={i} className={classes.rowsHover}>
                                            {columns.map((column: ColumnType, k: number) => (
                                                <TableCell align="center" key={k}>
                                                    {renderCell(row, column)}
                                                </TableCell>
                                            ))}
                                            {idField && copyRow && (
                                                <TableCell align="center">
                                                    <IconButton onClick={() => copyRow(row[idField])}>
                                                        <ContentCopyIcon />
                                                    </IconButton>
                                                </TableCell>
                                            )}
                                            {idField && statusField && updateStatus && (
                                                <TableCell align="center">
                                                    <PopupState variant="popover" popupId="demo-popup-menu">
                                                        {(popupState: any) => (
                                                            <>
                                                                <Button
                                                                    size="small"
                                                                    sx={{ backgroundColor: '#29CB4C', color: 'white' }}
                                                                    variant="contained"
                                                                    {...bindTrigger(popupState)}
                                                                >
                                                                    Change Status
                                                                </Button>
                                                                <Menu {...bindMenu(popupState)}>
                                                                    <MenuItem onClick={popupState.close}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={row?.isActive}
                                                                                    onChange={(event) =>
                                                                                        updateStatus({
                                                                                            ...row,
                                                                                            isActive: event.target.checked
                                                                                        })
                                                                                    }
                                                                                />
                                                                            }
                                                                            label="Active"
                                                                        />
                                                                    </MenuItem>
                                                                </Menu>
                                                            </>
                                                        )}
                                                    </PopupState>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow className={classes.rowsHover}>
                                        <TableCell align="center" colSpan={columns.length}>
                                            <h3>No Data Found</h3>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {page && count && rowsPerPage ? (
                        <Grid container spacing={3} alignItems="center" direction="row-reverse" style={{ padding: '1.5%' }}>
                            <Grid item>
                                <Pagination
                                    count={Math.ceil(count / rowsPerPage)}
                                    page={page}
                                    onChange={handleChangePage}
                                    showFirstButton
                                    showLastButton
                                />
                            </Grid>
                            <Grid item>
                                {' '}
                                <p>
                                    {pageStatus()}
                                </p>{' '}
                            </Grid>
                        </Grid>
                    ) : (
                        ''
                    )}
                </MainCard>
            </Grid>
        </Grid>
    );
}

const useStyle = makeStyles({
    rowsHover: {
        borderBottom: '1px solid #DDD',
        '&:hover': {
            backgroundColor: '#FFE'
        }
    }
});
