import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DateRangePicker from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Box, Button, Checkbox, FormControlLabel, Grid, Modal, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setCurrentUserForAddUser } from 'store/formsReducer';
import langString from 'utils/langString';
import AddUser from './AddUser';

export default function FormUserFilter({
    dateRange,
    setDateRange,
    published,
    setPublished,
    active,
    setActive,
    name,
    setName,
    setTargetType,
    reload
}: any) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (targetType: string) => {
        setAnchorEl(null);
        setTargetType(targetType);
        dispatch(setCurrentUserForAddUser([]));
    };

    return (
        <>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={12} sm={5}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateRangePicker
                            startText="Start"
                            endText="End"
                            value={dateRange}
                            onChange={(newValue) => setDateRange(newValue)}
                            renderInput={(startProps, endProps) => (
                                <>
                                    <TextField size="small" {...startProps} />
                                    <Box sx={{ mx: 2 }}> to </Box>
                                    <TextField size="small" {...endProps} />
                                </>
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
                {/* <Grid item xs={12} sm={1}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={published}
                                onChange={(event) => setPublished(event.target.checked)}
                                name="published"
                                color="primary"
                            />
                        }
                        label={langString('published')}
                    />
                </Grid> */}
                <Grid item xs={12} sm={3} textAlign="right">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={active}
                                onChange={(event) => setActive(event.target.checked)}
                                name="active"
                                color="primary"
                            />
                        }
                        label={langString('active')}
                    />
                </Grid>
                <Grid item xs={12} sm={2} textAlign="right">
                    <TextField
                        size="small"
                        id="outlined-select-name"
                        fullWidth
                        label={langString('username')}
                        value={name}
                        onChange={(event: any) => setName(event.target.value)}
                    />
                </Grid>

                <Grid item xs={12} sm={2} textAlign="right">
                    <Button size="large" onClick={handleClick} variant="contained" style={{ backgroundColor: '#72c946' }}>
                        <Typography component="h6">Add User</Typography>
                    </Button>
                    <Modal open={open} onClose={handleClose}>
                        <AddUser handleClose={handleClose} reload={reload} />
                    </Modal>
                </Grid>
            </Grid>
        </>
    );
}
