/* tslint:disable */
import { setProjectMember } from 'store/itemReducer';
import axiosServices from 'utils/axiosServices';
import { _deserailize } from 'utils/Deserialize';
import { errMsg } from 'utils/Helpers';
import { deleteProjectUserURL, projectTeamMemberURL } from 'utils/serverUrls';

export const fetchTeamMembersServerCall = async (
    projectId: any,
    currentPage: number,
    orgId: number,
    username: string,
    role: any,
    pageSize: number,
    snackbarMsg: any,
    dispatch: any
) => {
    const params = {
        projectId,
        currentPage,
        pageSize,
        username,
        roleName: role == 0 ? '' : JSON.parse(role).name,
        orgId: orgId == 0 ? undefined : orgId
    };

    try {
        const response = await axiosServices.get(`${projectTeamMemberURL}`, { params });
        const data = await _deserailize(response.data);
        return await new Promise((resolve, _) => {
            resolve({ data, count: response?.data?.meta?.count });
        });
    } catch (ex) {
        snackbarMsg(errMsg(ex), 'error');
        dispatch(setProjectMember(null));
        return await new Promise((resolve, _) => {
            resolve({ data: [], count: 0 });
        });
    }
};

export const deleteListItemServerCall = async (userId: number, projectId: any, snackbarMsg: any) => {
    const params = { userId, projectId };
    try {
        snackbarMsg('successfuly removed from team');
        return await axiosServices.delete(`${deleteProjectUserURL}`, { params });
    } catch (ex: any) {
        snackbarMsg(errMsg(ex), 'error');
        return await new Promise((resolve, _) => resolve({}));
    }
};

export default {};
