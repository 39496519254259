import { Serializer } from 'jsonapi-serializer';
import lodash from 'lodash';
import { setFormList, singleForm } from 'store/formsReducer';
import axiosServices from 'utils/axiosServices';
import { _deserailize } from 'utils/Deserialize';
import { errMsg } from 'utils/Helpers';
import { changeStatusURL, createOrUpdateFormTargetURL, getAllFormConfigurationURL, getUserByOrgsAndRolesURL, projectFormDetailsURL } from 'utils/serverUrls';

export const isPositive = (value: number) => value > 0;

export const setTargetOnChange = async ({ e, projectForms, item, dispatch }: any) => {
    const cloneRows = [...projectForms!];
    // eslint-disable-next-line array-callback-return
    cloneRows.map((row: any) => {
        if (row.id === item.id) {
            row.target = isPositive(parseInt(e.target.value, 10)) ? parseInt(e.target.value, 10) : 0;
        }
    });
    dispatch(setFormList(cloneRows));
};

export const setTargetOnBlur = async ({ e, item, snackbarMsg }: any) => {
    try {
        await axiosServices.post(createOrUpdateFormTargetURL, {
            formId: item.id,
            target: e.target.value
        });
        snackbarMsg('Target updated successfully');
    } catch (error: any) {
        const msg = error.response.data.errors.map((ex: any) => ex.detail).join(', ');
        snackbarMsg(msg, 'error');
    }
};

export const updateStatus = async ({ row, projectForms, dispatch, snackbarMsg }: any) => {
    try {
        const response = await axiosServices.post(changeStatusURL, {
            id: row.id,
            isPublished: row.isPublished,
            isActive: row.isActive
        });
        if (response.status === 200) {
            const allRows = [...projectForms!];
            const currentRowIndex = lodash.findIndex(allRows, (o) => o.id === row?.id);
            if (currentRowIndex > -1) {
                allRows[currentRowIndex] = row;
            }

            dispatch(setFormList(allRows));
            snackbarMsg('Status updated successfully');
        }
    } catch (error: any) {
        const msg = error.response.data.errors.map((e: any) => e.detail).join(', ');
        snackbarMsg(msg, 'error');
    }
};


export const getFormsDetails = async ({ projectId, formId, snackbarMsg, dispatch }: any) => {
    try {
        const response = await axiosServices.post(projectFormDetailsURL, {
            formId
        });
        const body = {
            orgList: [...response.data.organizations.map((org: any) => parseInt(org?.id, 10))],
            roleList: [...response.data?.roles.map((roleItem: any) => parseInt(roleItem?.id, 10))],
            projectId: parseInt(projectId?.id ? projectId?.id : '0', 10)
        };

        const serialize = await new Serializer('role', {
            keyForAttribute: 'camelCase',
            attributes: ['orgList', 'roleList', 'projectId']
        }).serialize(body);

        const userRes = await axiosServices.post(getUserByOrgsAndRolesURL, { ...serialize });
        const userList = await _deserailize(userRes.data);
        response.data.users = userList;
        response.data.formName = response.data.title;
        if (response.status === 200) {
            dispatch(singleForm(response.data));
        }
    } catch (error: any) {
        const msg = error.response.data.errors.map((e: any) => e.detail).join(', ');
        snackbarMsg(msg, 'error');
    }
};

export const fetchForms = async ({
    fromDate,
    toDate,
    projectId,
    formName,
    isPublished,
    isActive,
    currentPage,
    pageSize,
    snackbarMsg,
    dispatch
}: any) => {
    try {
        const params = {
            fromDate,
            toDate,
            projectId,
            formName,
            isPublished,
            isActive,
            currentPage,
            pageSize
        };
        const response = await axiosServices.post(`${getAllFormConfigurationURL}`, { ...params });

        const data = await _deserailize(response.data);
        return await new Promise((r, _) => r({ data, count: response?.data?.meta?.count }));
    } catch (ex) {
        console.log(ex);
        snackbarMsg(errMsg(ex), 'error');
        dispatch(setFormList(null));
        return await new Promise((r, _) => r({ data: [], count: 0 }));
    }
};
