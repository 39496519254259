import { Button, Grid, Typography } from '@mui/material';
import { ReactComponent as XlsExport } from '../../assets/images/icons/xlsExport.svg';
import FormUpload from './Upload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { styled } from '@mui/material/styles';
import useSnackbar from 'hooks/useSnackbar';
import { useState } from 'react';
import SubCard from 'ui-component/cards/SubCard';
import axiosFormService from 'utils/axiosFormServices';
import { getToken } from 'utils/Helpers';
import { projectFormUploadURL, projectFormReplaceURL } from 'utils/serverUrls';

const Input = styled('input')({
    display: 'none'
});

export default function UploadXLSForm({ handleClose, handleCloseModal, isReplace, project }: any) {
    const [xlxFile, setXlxfile] = useState<any>('');
    // const [labelText, setLabelText] = useState<string>(hoverLabel);
    const [isDragOver, setIsDragOver] = useState<boolean>(false);
    const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
    const snackbarMsg = useSnackbar();

    const fileToBase64 = (file: any) => {
        console.log(file);
        setXlxfile(file);
    };

    const getFileName = () => {
        if (xlxFile)
            return (
                <>
                    {' '}
                    <b>File name:</b> {xlxFile.name}
                </>
            );
        return null;
    };

    const onSubmit = async () => {
        console.log('submit', project);
        // if (project) {
        try {
            const payload = new FormData();
            payload.append('xls_file', xlxFile);
            if (project) {
                payload.append('project_id', project.id);
            }
            if (isReplace) {
                payload.append('id_string', project.idString);
            }

            // payload.append('project_id', project);

            const headers = {
                Authorization: `Bearer ${getToken('token')}`
            };
            console.log('payload', payload);
            isReplace
                ? await axiosFormService.post(`${projectFormReplaceURL}`, payload)
                : await axiosFormService.post(`${projectFormUploadURL}`, payload);
            snackbarMsg('Sucessfully Uploaded');
            handleClose(false);
            handleCloseModal(false);
        } catch (ex: any) {
            // const msg = ex.response.data.errors.map((e: any) => e.detail).join(', ');
            snackbarMsg('Server Error !', 'error');
        }
        // }
    };
    const stopDefaults = (e: React.DragEvent) => {
        e.stopPropagation();
        e.preventDefault();
    };
    const dragEvents = {
        onMouseEnter: () => {
            setIsMouseOver(true);
        },
        onMouseLeave: () => {
            setIsMouseOver(false);
        },
        onDragEnter: (e: React.DragEvent) => {
            stopDefaults(e);
            setIsDragOver(true);
            // setLabelText(dropLabel)
        },
        onDragLeave: (e: React.DragEvent) => {
            stopDefaults(e);
            setIsDragOver(false);
            // setLabelText(hoverLabel)
        },
        onDragOver: stopDefaults,
        onDrop: (e: React.DragEvent<HTMLElement>) => {
            stopDefaults(e);
            console.log('e?.dataTransfer?.files?.[0]', e?.dataTransfer?.files);
            fileToBase64(e?.dataTransfer?.files?.[0]);
        }
    };

    return (
        <>
            <label htmlFor="contained-button-file" {...dragEvents}>
                <div
                    style={{
                        padding: '20px',
                        border: '2px dashed gray',
                        borderRadius: 10,
                        backgroundColor: 'lightgray',
                        width: '100%',
                        textAlign: 'center',
                        cursor: 'pointer'
                    }}
                >
                    <Input
                        accept="/*"
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={(event: any) => {
                            fileToBase64(event?.currentTarget?.files?.[0]);
                        }}
                    />
                    {getFileName() ? (
                        getFileName()
                    ) : (
                        <>
                            <XlsExport />
                            <Typography variant="h2">Drag and drop the XLS file here</Typography>
                            <Typography variant="body2" fontSize={20}>
                                or <span style={{ color: 'blue', cursor: 'pointer' }}>click here to browse</span>
                            </Typography>
                        </>
                    )}
                </div>
            </label>
            <br />
            <Grid container justifyContent="center" alignItems="center" gap={1}>
                <Grid item xs={2}>
                    <Button variant="contained" onClick={handleClose}>
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs={2}>
                    <Button variant="contained" onClick={onSubmit}>
                        {isReplace ? 'Replace' : 'Create'}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}
