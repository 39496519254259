import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import ShareIcon from '@mui/icons-material/Share';
import { IconButton, Typography } from '@mui/material';
import TableActionButtons from 'components/Table/ActionButtons';
import { ColumnType } from 'components/Table/BasicDataTable';
import langString from 'utils/langString';
import { formsClone } from './Service';

export const DELETE = 'delete';
export const DOWNLOAD_XLS = 'xls';
export const DOWNLOAD_XML = 'xml';

export const getColumns = ({ actionButton, refresh, snackbarMsg }: any) => {
    const columns: ColumnType[] = [
        { header: langString('name'), accessor: 'title' },
        {
            header: langString('createdon'),
            accessor: 'formCreatedAt',
            content: (item: any) => <Typography>{new Date(item.formCreatedAt).toLocaleDateString()}</Typography>
        },
        {
            header: langString('lastModified'),
            accessor: 'formUpdatedAt',
            content: (item: any) => <Typography>{new Date(item.formUpdatedAt).toLocaleDateString()}</Typography>
        },
        {
            header: 'Action',
            accessor: 'action',
            content: (item: any) => (
                <>
                    <IconButton>
                        <RemoveRedEyeIcon />
                    </IconButton>
                    <IconButton>
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => formsClone({ item, refresh, snackbarMsg })}>
                        <ContentCopyIcon />
                    </IconButton>
                    <IconButton>
                        <ShareIcon />
                    </IconButton>
                    <TableActionButtons
                        options={[
                            { key: 'replace', label: 'Replace', icon: <PublishedWithChangesIcon /> },
                            { key: DOWNLOAD_XLS, label: 'Download XLS', icon: <DownloadIcon /> },
                            { key: DOWNLOAD_XML, label: 'Download XML', icon: <DownloadIcon /> },
                            { key: DELETE, label: 'Delete', icon: <DeleteForeverIcon /> }
                        ]}
                        action={(value: string) => actionButton({ value, item, refresh })}
                    />
                </>
            )
        }
    ];
    return columns;
};

export default {};
