import { useEffect, useState } from 'react';
import {
    Checkbox,
    Grid,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Pagination,
    Button
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ConfirmButton from 'views/AreaManagement/ConfirmButton';

type TableProps = {
    changeDetected?: boolean;
    data?: any;
    setData?: (obj: any[]) => void;
    setChangeDetected?: (value: boolean) => void;
    count?: number;
    page?: number;
    rowsPerPage?: number;
    setPage?: (value: number) => void;
    setRowsPerPage?: (value: number) => void;
    handleSubmit?: () => void;
    handleDelete: (id: number) => void;
    showSL?: boolean;
};

const UserFormTable = ({
    changeDetected,
    setChangeDetected,
    data,
    setData,
    count,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    handleSubmit,
    handleDelete,
    showSL
}: TableProps) => {
    const updateData = (updatedData: any) => {
        if (setData) {
            setData(updatedData);
            if (setChangeDetected) {
                setChangeDetected(true);
            }
        }
    };

    const updateTarget = (index: any, formIndex: any, value: any) => {
        const updatedData = [...data];
        console.log('***********************');
        console.log('index ====== >>> ', index);
        console.log('formIndex ====== >>> ', formIndex);
        console.log('***********************');
        updatedData[index].forms[formIndex].target = value;
        updateData(updatedData);
    };

    const updateFormStatus = (index: any, formIndex: any, value: any) => {
        const updatedData = [...data];
        console.log('***********************');
        console.log('index ====== >>> ', index);
        console.log('formIndex ====== >>> ', formIndex);
        console.log('***********************');
        updatedData[index].forms[formIndex].isActive = value;
        updateData(updatedData);
    };

    const handleChangePage = (event: any, newPage: number) => {
        if (setPage) {
            setPage(newPage);
        }
    };

    const pageStatus = () => {
        if (count && rowsPerPage && page) {
            let start = 1;
            let end = count;
            const totalPage = Math.ceil(count / rowsPerPage);
            if (page > 1) {
                start = (page - 1) * rowsPerPage + 1;
            }
            if (page < totalPage) {
                end = start + rowsPerPage - 1;
            }
            return `Showing ${start} to ${end} of ${count} results.`;
        }
        return '';
    };

    return (
        <Grid style={{ marginTop: '10px' }}>
            <MainCard content={false}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {showSL && <TableCell align="center">Sl. No.</TableCell>}
                                <TableCell align="center">Full Name</TableCell>
                                <TableCell align="center">Username</TableCell>
                                <TableCell align="center">Target</TableCell>
                                <TableCell align="center">Forms</TableCell>
                                <TableCell align="center">Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data &&
                                data.map((item: any, index: number) => (
                                    <>
                                        <TableRow key={index}>
                                            {showSL && (
                                                <TableCell rowSpan={item.forms.length || 1} align="center">
                                                    <Typography variant="subtitle1" noWrap>
                                                        {index + 1}
                                                    </Typography>
                                                </TableCell>
                                            )}
                                            <TableCell rowSpan={item.forms.length || 1} align="center">
                                                {item.name}
                                            </TableCell>
                                            <TableCell rowSpan={item.forms.length || 1} align="center">
                                                {item.username}
                                            </TableCell>
                                            <TableCell align="center">
                                                {item.forms.length > 0 && (
                                                    <input
                                                        style={{
                                                            width: '50%',
                                                            textAlign: 'center',
                                                            padding: ' 2px 5px',
                                                            border: 0,
                                                            backgroundColor: 'lightgray',
                                                            borderRadius: '5px'
                                                        }}
                                                        onChange={(e) => updateTarget(index, 0, e.target.value)}
                                                        // onBlur={async (e) => setTargetOnBlur({ e, formId, item, snackbarMsg })}
                                                        value={item.forms[0]?.target}
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell align="center">
                                                {item.forms.length > 0 && (
                                                    <>
                                                        {item.forms[0]?.form}
                                                        <Checkbox
                                                            checked={item.forms[0]?.isActive}
                                                            // disabled={row?.isPublished === false}
                                                            onChange={(e) => {
                                                                updateFormStatus(index, 0, e.target.checked);
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            </TableCell>
                                            <TableCell align="center">
                                                {item.forms.length > 0 && (
                                                    <>
                                                        <ConfirmButton
                                                            variant="outlined"
                                                            buttonText={<DeleteForeverIcon />}
                                                            subTitle={`Delete Team Member Form access for user: ${item.name}, form: ${item.forms[0]?.form}`}
                                                            confirmed={() => handleDelete(item.forms[0]?.id)}
                                                        />
                                                    </>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                        {item.forms.slice(1).map((formObj: any, formIndex: number) => (
                                            <TableRow key={`${index}-${formIndex}`}>
                                                <TableCell align="center">
                                                    <input
                                                        style={{
                                                            width: '50%',
                                                            textAlign: 'center',
                                                            padding: ' 2px 5px',
                                                            border: 0,
                                                            backgroundColor: 'lightgray',
                                                            borderRadius: '5px'
                                                        }}
                                                        onChange={(e) => updateTarget(index, formIndex + 1, e.target.value)}
                                                        // onBlur={async (e) => setTargetOnBlur({ e, formId, item, snackbarMsg })}
                                                        value={formObj?.target}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    {formObj?.form}
                                                    <Checkbox
                                                        checked={formObj?.isActive}
                                                        onChange={(e) => {
                                                            updateFormStatus(index, formIndex + 1, e.target.checked);
                                                        }}
                                                        // disabled={row?.isPublished === false}
                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <ConfirmButton
                                                        variant="outlined"
                                                        buttonText={<DeleteForeverIcon />}
                                                        subTitle={`Delete Team Member Form access for user: ${item.name}, form: ${formObj.form}`}
                                                        confirmed={() => handleDelete(formObj.id)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* {page && count && rowsPerPage ? (
                    <Grid container spacing={3} alignItems="center" direction="row-reverse" style={{ padding: '1.5%' }}>
                        <Grid item>
                            <Pagination
                                count={Math.ceil(count / rowsPerPage)}
                                page={page}
                                onChange={handleChangePage}
                                showFirstButton
                                showLastButton
                            />
                        </Grid>
                        <Grid item>
                            {' '}
                            <p>{pageStatus()}</p>{' '}
                        </Grid>
                    </Grid>
                ) : (
                    ''
                )} */}
            </MainCard>
            {changeDetected && (
                <Grid container spacing={2} direction="row-reverse" style={{ marginTop: '10px' }}>
                    <Grid item>
                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            // disabled={changeDetected && completePolygonCount > 0 && polygonCount === completePolygonCount ? false : true}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default UserFormTable;
