import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography
} from '@mui/material';
import { ReactComponent as Message } from 'assets/images/icons/message.svg';
import { useFormik } from 'formik';
import useQuery from 'hooks/useQuery';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Loading from 'ui-component/Loding';

import AuthCardWrapper from 'views/authentication/AuthCardWrapper';
import AuthWrapper from 'views/authentication/AuthWrapper';
import { setPassword } from 'views/authentication/Service';
import * as Yup from 'yup';

export const LOADING = 1;
export const SUCCESS = 2;
export const FAILED = 3;

const SuccessResponse = () => (
    <>
        <Grid item xs={12} sm={12} textAlign="center">
            <CheckIcon sx={{ fontSize: '900%', color: 'skyblue' }} />

            <Typography variant="h1" component="div" sx={{ fontWeight: '100' }}>
                Password Changed
            </Typography>
            <div style={{ padding: '5%' }}>
                <hr />
            </div>
            <Typography variant="subtitle1">Your password has been changed successfully.</Typography>
            <Typography>You can now sign-in to your account.</Typography>
        </Grid>
        <Grid container>
            <Grid item xs={12} sx={{ margin: 3, textAlign: 'center' }}>
                <Button component={Link} to="/login" color="primary" variant="contained">
                    Sign in
                </Button>
            </Grid>
        </Grid>
    </>
);

const ErrorResponse = () => {
    const navigate = useNavigate();
    return (
        <>
            <Grid item xs={12} sm={12} textAlign="center">
                <ErrorOutlineIcon sx={{ fontSize: '900%', color: 'red' }} />

                <Typography variant="h1" component="div" sx={{ fontWeight: '100' }}>
                    Failed
                </Typography>
                <div style={{ padding: '5%' }}>
                    <hr />
                </div>
                <Typography marginBottom={2} variant="subtitle1">
                    Sorry !. can not change password.
                </Typography>

                <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    startIcon={<ArrowBackIosIcon />}
                    onClick={() => navigate('/login')}
                >
                    Login
                </Button>
            </Grid>
        </>
    );
};

const validationSchema = Yup.object().shape({
    password: Yup.string().required('Password is required').min(6, 'Password must be at least 6 characters'),
    confirmPassword: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password')], 'Passwords must match')
});

export default function NewPassword() {
    const [stage, setStage] = useState<number>(0);
    const { id } = useQuery('id');

    const navigate = useNavigate();
    const formik = useFormik({
        enableReinitialize: true,
        validationSchema,
        initialValues: {
            password: '',
            confirmPassword: '',
            passwordShow: false,
            confirmPasswordShow: false
        },
        onSubmit: (values) => setPassword({ values, id, setStage })
    });
    return (
        <AuthWrapper>
            <Grid container justifyContent="center" alignItems="center">
                <AuthCardWrapper>
                    {stage === 0 && (
                        <Box display="flex" marginY="auto" overflow="auto" height="100%" alignItems="center" justifyContent="center">
                            <form style={{ width: '80%' }} onSubmit={formik.handleSubmit}>
                                <Box textAlign="center">
                                    <Message />
                                </Box>
                                <Box textAlign="center">
                                    <Typography variant="h3">Reset Password</Typography>
                                </Box>
                                <Divider style={{ marginTop: 20 }} />
                                <br />
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                    <OutlinedInput
                                        inputProps={{}}
                                        id="outlined-adornment-password"
                                        name="password"
                                        label="Password"
                                        type={formik.values.passwordShow ? 'text' : 'password'}
                                        value={formik.values.password}
                                        onChange={(event: any) => formik.setFieldValue('password', event.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => formik.setFieldValue('passwordShow', !formik.values.passwordShow)}
                                                    edge="end"
                                                    size="large"
                                                >
                                                    {formik.values.passwordShow ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.password && Boolean(formik.errors.password)}
                                    />

                                    <FormHelperText error>
                                        {formik.errors.password && formik.touched.password && formik.errors.password}
                                    </FormHelperText>
                                </FormControl>

                                <FormControl fullWidth variant="outlined" sx={{ marginTop: 2 }}>
                                    <InputLabel htmlFor="outlined-adornment-confirmPassword">Confirm Password</InputLabel>
                                    <OutlinedInput
                                        inputProps={{}}
                                        id="outlined-adornment-confirmPassword"
                                        name="confirmPassword"
                                        label="Confirm Password*"
                                        type={formik.values.confirmPasswordShow ? 'text' : 'password'}
                                        value={formik.values.confirmPassword}
                                        onChange={(event: any) => formik.setFieldValue('confirmPassword', event.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() =>
                                                        formik.setFieldValue('confirmPasswordShow', !formik.values.confirmPasswordShow)
                                                    }
                                                    edge="end"
                                                    size="large"
                                                >
                                                    {formik.values.confirmPasswordShow ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                    />

                                    <FormHelperText error>
                                        {formik.errors.confirmPassword && formik.touched.confirmPassword && formik.errors.confirmPassword}
                                    </FormHelperText>
                                </FormControl>

                                <Box display="flex" justifyContent="space-between" marginTop={2}>
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        startIcon={<ArrowBackIosIcon />}
                                        onClick={() => navigate('/login')}
                                    >
                                        Return
                                    </Button>
                                    <Button type="submit" variant="contained" color="primary" endIcon={<ArrowForwardIosIcon />}>
                                        Reset
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    )}

                    {stage === LOADING ? (
                        <>
                            <Loading />
                        </>
                    ) : null}
                    {stage === SUCCESS ? <SuccessResponse /> : null}
                    {stage === FAILED ? <ErrorResponse /> : null}
                </AuthCardWrapper>
            </Grid>
        </AuthWrapper>
    );
}
