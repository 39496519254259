import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { Box, Button, Grid, Typography } from '@mui/material';
import BasicDataTable from 'components/Table/BasicDataTable';
import useSnackbar from 'hooks/useSnackbar';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { setProjectMember } from 'store/itemReducer';
import { TRootState } from 'store/reducer';
import Loader from 'ui-component/Loader';
import Loading from 'ui-component/Loding';
import { CURRENT_PAGE, PAGE_SIZE } from 'utils/Constants';
import langString from 'utils/langString';
import { ProjectType, teamColumns } from '../Constants';
import ProjectTeamsFilter from './Filter';
import { deleteListItemServerCall, fetchTeamMembersServerCall } from './Service';

type ProjectTeamsPropsType = {
    project: ProjectType;
};
export default function ProjectTeams() {
    const [loading, setLoading] = useState(false);
    const [organization, setOrganization] = useState(0);
    const [role, setRole] = useState<any>(0);
    const [name, setName] = useState('');
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(CURRENT_PAGE);
    const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE);
    const account = useSelector((state: any) => state.account);
    const project = useSelector((state: any) => state.projects.currentProject);
    const { projectMember } = useSelector((state: TRootState) => state.item);
    const { id: projectId } = useParams();
    const snackbarMsg = useSnackbar();
    const dispatch = useDispatch();
    const currentProject = useSelector((state: any) => state.projects.currentProject);
    const deleteListItem = async (userId: number) => {
        await deleteListItemServerCall(userId, projectId, snackbarMsg);
        fetchTeamMembers();
    };

    const init = async () => {
        setLoading(true);
        try {
            fetchTeamMembers();
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const fetchTeamMembers = async () => {
        const result: any = await fetchTeamMembersServerCall(projectId, page, organization, name, role, rowsPerPage, snackbarMsg, dispatch);
        dispatch(setProjectMember(result.data));
        setCount(result.count);
    };

    useEffect(() => {
        if (account.user) init();
    }, [page, rowsPerPage, role]);

    useEffect(() => {
        if (organization > 0) {
            setRole(0);
            init();
        }
    }, [organization]);

    useEffect(() => {
        init();
    }, [name]);

    return (
        <>
            {loading && <Loader />}
            <Grid container>
                <Grid item xs={12} sm={10}>
                    {project && (
                        <ProjectTeamsFilter
                            project={project}
                            organization={organization}
                            setOrganization={(value: number) => setOrganization(value)}
                            role={role}
                            setRole={(value: number) => setRole(value)}
                            name={name}
                            setName={(value: string) => setName(value)}
                            filter={() => init()}
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Button
                        disabled={currentProject?.isArchived}
                        component={Link}
                        to={`/project/${projectId}/add-team-member`}
                        variant="contained"
                        color="success"
                        sx={{ float: 'right' }}
                    >
                        <GroupAddIcon /> {langString('addmember')}
                    </Button>
                </Grid>
            </Grid>

            <Box width="100%" sx={{ marginTop: 2 }} textAlign="center">
                {/* eslint-disable-next-line no-nested-ternary */}
                {typeof projectMember === 'undefined' ? (
                    <Loading />
                ) : projectMember === null ? (
                    <Typography sx={{ marginX: 'auto', marginY: 2 }} variant="h3">
                        Data not found
                    </Typography>
                ) : (
                    <BasicDataTable
                        rows={projectMember}
                        columns={teamColumns({ deleteListItem })}
                        count={count}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        setPage={(value: number) => setPage(value)}
                        setRowsPerPage={(value: number) => setRowsPerPage(value)}
                        showSL
                    />
                )}
            </Box>
        </>
    );
}
