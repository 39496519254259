// third-party
// assets
import {
    IconDashboard,
    IconHelp,
    IconSitemap,
    IconUsers,
    IconUser,
    IconFileCode,
    IconBuildingCommunity,
    IconClipboardList,
    IconUpload,
    IconDatabase,
    IconLayout2,
    IconCurrentLocation,
    IconSettings,
    IconList
} from '@tabler/icons';
import { FormattedMessage } from 'react-intl';
import LockIcon from '@mui/icons-material/Lock';

// constant
const icons = {
    IconDashboard,
    IconHelp,
    IconSitemap,
    IconUsers,
    IconUser,
    IconFileCode,
    IconBuildingCommunity,
    IconClipboardList,
    IconUpload,
    IconDatabase,
    IconLayout2,
    IconCurrentLocation,
    IconSettings,
    IconList
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'surveyList',
            title: <FormattedMessage id="surveyList" />,
            type: 'item',
            url: '/',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'team_management',
            title: <FormattedMessage id="teamManagement" />,
            type: 'collapse',
            url: '/',
            icon: icons.IconUsers,
            breadcrumbs: false,
            children: [
                // {
                //     id: 'role_management',
                //     title: <FormattedMessage id="roleManagement" />,
                //     type: 'item',
                //     url: '/roles',
                //     icon: icons.IconClipboardList,
                //     breadcrumbs: false
                // },
                {
                    id: 'user_management',
                    title: <FormattedMessage id="userManagement" />,
                    type: 'item',
                    url: '/users',
                    icon: icons.IconUser,
                    breadcrumbs: false
                },
                {
                    id: 'org_management',
                    title: <FormattedMessage id="orgManagement" />,
                    type: 'item',
                    url: '/organizations',
                    icon: icons.IconBuildingCommunity,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'areaManagement',
            title: <FormattedMessage id="areaManagement" />,
            type: 'collapse',
            url: '/',
            icon: icons.IconCurrentLocation,
            breadcrumbs: false,
            children: [
                {
                    id: 'areaConfiguration',
                    title: <FormattedMessage id="areaConfiguration" />,
                    type: 'item',
                    url: '/areas/create',
                    icon: icons.IconSettings,
                    breadcrumbs: false
                },
                {
                    id: 'areaList',
                    title: <FormattedMessage id="areaList" />,
                    type: 'item',
                    url: '/areas',
                    icon: icons.IconList,
                    breadcrumbs: false
                }
            ]
        },
        // {
        //     id: 'logout',
        //     title: <FormattedMessage id="logout" />,
        //     type: 'item',
        //     url: '/logout',
        //     icon: icons.IconLogout,
        //     breadcrumbs: false
        // }
        // {
        //     id: 'forms',
        //     title: <FormattedMessage id="formdesignandupload" />,
        //     type: 'item',
        //     url: '/forms',
        //     icon: icons.IconFileCode,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'notifications',
        //     title: <FormattedMessage id="Notifications" />,
        //     type: 'item',
        //     url: '/notifications',
        //     icon: IconBell,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'permission',
        //     title: <FormattedMessage id="Permission" />,
        //     type: 'item',
        //     url: '/permission',
        //     icon: LockIcon,
        //     breadcrumbs: false
        // },
        // {
        //     id: 'master_data',
        //     title: <FormattedMessage id="Master Data" />,
        //     type: 'collapse',
        //     url: '/',
        //     icon: icons.IconDatabase,
        //     breadcrumbs: false,
        //     children: [
        //         {
        //             id: 'category',
        //             title: <FormattedMessage id="Category" />,
        //             type: 'item',
        //             url: '/category',
        //             icon: icons.IconLayout2,
        //             breadcrumbs: false
        //         }
        //     ]
        // }

        // {
        //     id: 'feedback',
        //     title: <FormattedMessage id="feedback" />,
        //     type: 'item',
        //     url: '/feedback',
        //     icon: icons.IconHelp,
        //     breadcrumbs: false
        // },
    ]
};
export const superUser = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'user-create',
            title: <FormattedMessage id="User Create" />,
            type: 'item',
            url: '/super-user/users-create',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'acl',
            title: <FormattedMessage id="ACL" />,
            type: 'item',
            url: '/super-user/acl',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'forum-acl',
            title: <FormattedMessage id="Forum ACL" />,
            type: 'item',
            url: '/super-user/forums',
            icon: icons.IconDashboard,
            breadcrumbs: false
        }
    ]
};

export default other;
