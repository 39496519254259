// type action
export const COMPOSE = 'COMPOSE';
export const COMPOSE_RESET = 'COMPOSE_RESET';
export const INBOX = 'INBOX';
export const SEND = 'SEND';

const initialState = {
    compose: {},
    inbox: {},
    send: {},
}

export const notificationReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case COMPOSE:
            return {
                ...state,
                compose: {
                    ...state.compose,
                    ...action.payload
                },
            };
        case COMPOSE_RESET:
            return {
                ...state,
                compose: {},
            };
        case INBOX:
            return {
                ...state,
                inbox: {
                    ...state.inbox,
                    ...action.payload
                }
            };
        case SEND:
            return {
                ...state,
                send: {
                    ...state.send,
                    ...action.payload
                }
            };
        default:
            return state;
    }
};

// action creator
export const composeAction = (payload: any) => ({
    type: COMPOSE,
    payload
});
export const composeResetAction = () => ({
    type: COMPOSE_RESET
});

export const inboxAction = (payload: any) => ({
    type: INBOX,
    payload
});

export const sendAction = (payload: any) => ({
    type: SEND,
    payload
});