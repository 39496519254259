import ClearIcon from '@mui/icons-material/Clear';
import {
    Button,
    Checkbox,
    Grid,
    IconButton,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import useSnackbar from 'hooks/useSnackbar';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setCurrentUserForAddUser, setUsersForAddUser } from 'store/formsReducer';
import MainCard from 'ui-component/cards/MainCard';
import Loading from 'ui-component/Loding';
import langString from 'utils/langString';
import Filter from './Filter';
import { init, onSubmit } from './Service';

export default function AddUser({ handleClose, reload }: any) {
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [count, setCount] = useState(0);
    const { userName, organizations, roles } = useSelector((state: any) => state?.form?.addUser?.filter?.current);
    const { users, currentUser } = useSelector((state: any) => state?.form?.addUser);
    const { projectId, formId } = useParams();

    const dispatch = useDispatch();
    const snackbarMsg = useSnackbar();

    const handleChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page);
    };

    const handleAllSelect = (e: any) => {
        if (e.target.checked) {
            const cloneUserList = _.cloneDeep(users);
            cloneUserList.map((item: any) => {
                item.isChecked = e.target.checked;
            });

            dispatch(setUsersForAddUser(cloneUserList));
            dispatch(setCurrentUserForAddUser(users));
        } else {
            const cloneUserList = _.cloneDeep(users);
            cloneUserList.map((item: any) => {
                item.isChecked = e.target.checked;
            });

            dispatch(setCurrentUserForAddUser([]));
            dispatch(setUsersForAddUser(cloneUserList));
        }
    };

    const handleOnCheck = (e: any, user: any) => {
        if (e.target.checked) {
            const cloneUserList = _.cloneDeep(users);
            cloneUserList.map((item: any) => {
                if (item.id === user.id) {
                    if (e.target.checked) {
                        item.isChecked = e.target.checked;
                    }
                }
            });

            dispatch(setCurrentUserForAddUser([...currentUser, user]));
            dispatch(setUsersForAddUser(cloneUserList));
        } else {
            const cloneUserList = _.cloneDeep(users);
            const currentUsers = _.cloneDeep(currentUser);
            cloneUserList.map((item: any) => {
                if (item.id === user.id) {
                    item.isChecked = e.target.checked;
                }
            });
            const unCheckUser = currentUsers.filter((itm: any) => itm.id !== user.id);
            dispatch(setCurrentUserForAddUser(unCheckUser));
            dispatch(setUsersForAddUser(cloneUserList));
        }
    };

    const pageStatus = () => {
        if (count && pageSize && currentPage) {
            let start = 1;
            let end = count;
            const totalPage = Math.ceil(count / pageSize);
            if (currentPage > 1) {
                start = (currentPage - 1) * pageSize + 1;
            }
            if (currentPage < totalPage) {
                end = start + pageSize - 1;
            }
            return `Showing ${start} to ${end} of ${count} results.`;
        }
        return '';
    };

    useEffect(() => {
        init({
            projectId,
            formId,
            org_id: organizations.map((item: any) => item.orgId),
            role_id: roles.map((item: any) => parseInt(item.id, 10)),
            username: userName,
            pageSize,
            currentPage,
            snackbarMsg,
            dispatch,
            setCount
        });
    }, [currentPage, userName, organizations, roles]);

    return (
        <MainCard
            title="Add User"
            secondary={
                <IconButton onClick={handleClose}>
                    <ClearIcon />
                </IconButton>
            }
            sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90%',
                height: '90%',
                overflow: 'auto',
                backgroundColor: 'white',
                padding: 4,
                borderRadius: 5
            }}
        >
            <Filter />
            <br />
            <MainCard>
                <TableContainer>
                    <Typography variant="h3" gutterBottom textAlign="center">
                        Add User
                    </Typography>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ border: '1px solid lightgrey' }}>
                                    {' '}
                                    <Checkbox onChange={(e) => handleAllSelect(e)} />
                                    Select All
                                </TableCell>
                                <TableCell style={{ border: '1px solid lightgrey' }}>SL</TableCell>
                                <TableCell style={{ border: '1px solid lightgrey' }}>User Name</TableCell>
                                <TableCell style={{ border: '1px solid lightgrey' }}>Organization</TableCell>
                                <TableCell style={{ border: '1px solid lightgrey' }}>Role</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {typeof users === 'undefined' ? (
                                <Loading />
                            ) : users === null ? (
                                <Typography sx={{ marginX: 'auto', marginY: 2 }} variant="h3">
                                    Data not found
                                </Typography>
                            ) : (
                                users.map((user: any, index: number) => (
                                    <TableRow key={user?.id}>
                                        <TableCell scope="row" style={{ border: '1px solid lightgrey' }}>
                                            <Checkbox checked={user?.isChecked} onChange={(e) => handleOnCheck(e, user)} />
                                            {`${user?.firstName ? user?.firstName : ''} ${user?.lastName ? user?.lastName : ''}`}
                                        </TableCell>

                                        <TableCell style={{ border: '1px solid lightgrey' }}>{user?.sl ? user?.sl : ''}</TableCell>

                                        <TableCell style={{ border: '1px solid lightgrey' }}>
                                            {`${user?.firstName ? user?.firstName : ''} ${user?.lastName ? user?.lastName : ''}`}
                                        </TableCell>

                                        <TableCell style={{ border: '1px solid lightgrey' }}>
                                            {user?.organization ? user?.organization : ''}
                                        </TableCell>

                                        <TableCell style={{ border: '1px solid lightgrey' }}>{user?.role ? user?.role : ''}</TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {currentPage && count && pageSize ? (
                    <Grid container spacing={3} alignItems="center" direction="row-reverse" style={{ padding: '1.5%' }}>
                        <Grid item>
                            <Pagination
                                count={Math.ceil(count / pageSize)}
                                page={currentPage}
                                onChange={handleChangePage}
                                showFirstButton
                                showLastButton
                            />
                        </Grid>
                        <Grid item>
                            {' '}
                            <p>{pageStatus()}</p>{' '}
                        </Grid>
                    </Grid>
                ) : (
                    ''
                )}
            </MainCard>
            <Grid marginTop={2} container alignItems="center" justifyContent="space-between">
                <Grid item xs={12} textAlign="center">
                    <Button sx={{ marginRight: 2 }} onClick={handleClose} variant="contained" color="inherit">
                        {langString('cancel')}
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => onSubmit({ formId, currentUser, dispatch, snackbarMsg, reload, handleClose })}
                    >
                        {langString('save')}
                    </Button>
                </Grid>
            </Grid>
        </MainCard>
    );
}
