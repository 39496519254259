import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Checkbox, FormControl, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useSnackbar from 'hooks/useSnackbar';
import { Serializer } from 'jsonapi-serializer';
import lodash from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { gridSpacing } from 'store/constant';
import {
    setCurrentImportForm,
    setCurrentOrganizations,
    setCurrentRoles,
    setCurrentUsers,
    setImportFormList,
    setOrganizations,
    setRoles,
    setUsers
} from 'store/formsReducer';
import axiosServices from 'utils/axiosServices';
import { _deserailize } from 'utils/Deserialize';
import langString from 'utils/langString';
import { getAllByOrgsURL, getProjectOrganizationsURL, getUserByOrgsAndRolesURL, templateListURL } from 'utils/serverUrls';
import EditTable from './EditTable';
import TableUser from './Table';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3
};

export default function EditFormCascading({ formId }: any) {
    const theme = useTheme();
    const [errors, setErrors] = useState<any>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { importForm } = useSelector((state: any) => state?.form);
    const projectId = useParams();
    const snackbarMsg = useSnackbar();
    const dispatch = useDispatch();

    const onOrganizationChange = async (_: any, value: any) => {
        dispatch(setCurrentOrganizations(value));

        try {
            const body = {
                orgList: [...value.map((org: any) => parseInt(org?.orgId, 10))]
            };

            const serialize = await new Serializer('role', {
                keyForAttribute: 'camelCase',
                attributes: ['orgList']
            }).serialize(body);

            const roleRes = await axiosServices.post(getAllByOrgsURL, { ...serialize });
            const roleList = await _deserailize(roleRes.data);

            dispatch(setRoles(roleList));
            dispatch(setCurrentRoles([]));
            dispatch(setUsers([]));
            dispatch(setCurrentUsers([]));
        } catch (e) {
            console.log(e);
        }
    };
    const onRoleChange = async (_: any, value: any) => {
        dispatch(setCurrentRoles(value));
        try {
            (async () => {
                try {
                    const body = {
                        orgList: [...importForm?.currentState?.organizations.map((org: any) => parseInt(org?.orgId, 10))],
                        roleList: [...importForm?.currentState?.roles.map((roleItem: any) => parseInt(roleItem?.id, 10))],
                        projectId: parseInt(projectId?.id ? projectId?.id : '0', 10)
                    };

                    const serialize = await new Serializer('role', {
                        keyForAttribute: 'camelCase',
                        attributes: ['orgList', 'roleList', 'projectId']
                    }).serialize(body);

                    const userRes = await axiosServices.post(getUserByOrgsAndRolesURL, { ...serialize });
                    const userList = await _deserailize(userRes.data);
                    const cloneUserList = lodash.cloneDeep(userList);
                    cloneUserList.map((userItem: any) => {
                        userItem.isChecked = false;
                    });

                    // dispatch(setUsers(cloneUserList));
                    dispatch(setCurrentUsers(cloneUserList));
                } catch (e) {
                    console.log(e);
                }
            })();
        } catch (e) {
            console.log(e);
        }
    };

    // useEffect(() => {
    //     (async () => {
    //         try {
    //             const body = {
    //                 orgList: [...importForm?.currentState?.organizations.map((org: any) => parseInt(org?.id, 10))],
    //                 roleList: [...importForm?.currentState?.roles.map((roleItem: any) => parseInt(roleItem?.id, 10))],
    //                 projectId: parseInt(projectId?.id ? projectId?.id : '0', 10)
    //             };

    //             const serialize = await new Serializer('role', {
    //                 keyForAttribute: 'camelCase',
    //                 attributes: ['orgList', 'roleList', 'projectId']
    //             }).serialize(body);

    //             const userRes = await axiosServices.post(getUserByOrgsAndRolesURL, { ...serialize });
    //             const userList = await _deserailize(userRes.data);
    //             const cloneUserList = lodash.cloneDeep(userList);

    //             // dispatch(setUsers(cloneUserList));
    //             console.log('-------------------------------------------------------------');
    //             dispatch(setCurrentUsers(cloneUserList));
    //         } catch (e) {
    //             console.log(e);
    //         }
    //     })();
    // }, [formId]);

    return (
        <>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <Typography variant="h5">{langString('access')}</Typography>
                </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={gridSpacing} justifyContent="space-between" sx={{ padding: 2 }}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">{`${langString('organization')} *`}</Typography>
                    <FormControl
                        fullWidth
                        error={Boolean(errors && errors.organization?.required)}
                        sx={{ ...theme.typography.customInput }}
                    >
                        <Autocomplete
                            disabled
                            multiple
                            limitTags={2}
                            id="checkboxes-tags-organization"
                            disableCloseOnSelect
                            options={[]}
                            value={typeof importForm?.currentState?.organizations === 'undefined' ? [] : importForm?.currentState?.organizations}
                            getOptionLabel={(option: any) => option.name}
                            onChange={onOrganizationChange}
                            style={{ width: '100%' }}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                        {Boolean(errors && errors.organization?.required) && (
                            <FormHelperText error>{errors.organization?.message}</FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6">{langString('role')}</Typography>
                    <FormControl fullWidth error={Boolean(errors && errors.role?.required)} sx={{ ...theme.typography.customInput }}>
                        <Autocomplete
                            disabled
                            multiple
                            limitTags={2}
                            disableCloseOnSelect
                            id="checkboxes-tags-role"
                            options={importForm?.roles}
                            value={typeof importForm?.currentState?.roles === 'undefined' ? [] : importForm?.currentState?.roles}
                            getOptionLabel={(option: any) => option.name}
                            renderOption={(props: any, option: any, { selected }: any) => (
                                <li {...props}>
                                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                    {option.name}
                                </li>
                            )}
                            onChange={onRoleChange}
                            style={{ width: '100%' }}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                        {Boolean(errors && errors.role?.required) && <FormHelperText error>{errors.role?.message}</FormHelperText>}
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item xs={12}>
                    <EditTable />
                </Grid>
            </Grid>
        </>
    );
}
