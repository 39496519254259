// action type
export const CURRENT_TAB = 'formDetails/currentTab';
export const ALL_USER_DATA = 'formDetails/allUserData';
export const EXPORT_DATA = 'formDetails/exportData';
export const REPORT_CHART_TYPE = 'formDetails/report/chartType';
export const REPORT_CHART_DATA = 'formDetails/report/chartData';

// ==============================|| type of action ||============================== //
type TCurrentTab = typeof CURRENT_TAB;
type TAllUserData = typeof ALL_USER_DATA;
type TExportData = typeof EXPORT_DATA;
type TReportChartType = typeof REPORT_CHART_TYPE;
type TReportChartData = typeof REPORT_CHART_DATA;

type TActionTypes = TCurrentTab | TAllUserData | TExportData | TReportChartType | TReportChartData;

// ==============================|| action - state management ||============================== //
interface IFormDetails {
    currentTab?: any;
    allUserData?: any | any[];
    exportData?: any | any[];
    report?: {
        chartType?: string;
        chartData?: any;
    };
}

const initialState: IFormDetails = {
    currentTab: undefined,
    allUserData: undefined,
    exportData: undefined,
    report: {
        chartType: 'pie'
    }
};

// ==============================|| REDUCER ||============================== //

export interface IProjectsReducerActionProps {
    type: TActionTypes;
    payload?: IFormDetails;
}

const formDetailsReducer = (state = initialState, action: IProjectsReducerActionProps) => {
    switch (action.type) {
        case CURRENT_TAB: {
            return {
                ...state,
                ...action.payload
            };
        }
        case ALL_USER_DATA: {
            return {
                ...state,
                ...action.payload
            };
        }
        case EXPORT_DATA: {
            return {
                ...state,
                ...action.payload
            };
        }
        case REPORT_CHART_TYPE: {
            return {
                ...state,
                report: {
                    ...state.report,
                    ...action.payload
                }
            };
        }
        case REPORT_CHART_DATA: {
            return {
                ...state,
                report: {
                    ...state.report,
                    ...action.payload
                }
            };
        }
        default: {
            return state;
        }
    }
};

export default formDetailsReducer;

// ==============================|| action creator ||============================== //

export const setCurrentTab = (currentTab: any | null) => ({
    type: CURRENT_TAB,
    payload: { currentTab }
});

export const setAllUserData = (allUserData: any | null) => ({
    type: ALL_USER_DATA,
    payload: { allUserData }
});

export const setExportData = (exportData: any | null) => ({
    type: EXPORT_DATA,
    payload: { exportData }
});
export const setReportType = (chartType: string) => ({
    type: REPORT_CHART_TYPE,
    payload: { chartType }
});

export const setReportData = (chartData: any | null) => ({
    type: EXPORT_DATA,
    payload: { chartData }
});
