import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';

type OptionType = {
    key: string;
    label: string;
    icon?: React.ReactNode;
};
type TableActionButtonsPropsType = {
    ITEM_HEIGHT?: number;
    options: OptionType[];
    action: (value: string) => void;
};

export default function TableActionButtons({ options, action, ITEM_HEIGHT = 48 }: TableActionButtonsPropsType) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (value: string) => {
        console.log(value);
        action(value);
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="long-menu"
                MenuListProps={{
                    'aria-labelledby': 'long-button'
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '20ch'
                    }
                }}
            >
                {options &&
                    options.map((option) => (
                        <MenuItem key={option.key} onClick={() => handleClose(option.key)}>
                            {option.icon && option.icon} <Typography sx={{ marginLeft: 1 }}>{option.label}</Typography>
                        </MenuItem>
                    ))}
            </Menu>
        </>
    );
}
