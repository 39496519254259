import { Link, Typography } from '@mui/material';
import langString from '../../../../../utils/langString';
import { setTargetOnBlur, setTargetOnChange } from './Service';

export const columns = ({ formId, userList, dispatch, snackbarMsg, goToFormData }: any) => [
    {
        header: 'User Name',
        content: (item: any) => (
            <Link style={{ cursor: 'pointer' }} onClick={(e: any) => goToFormData(item.id)}>
                {' '}
                {`${item?.firstName ? item.firstName : ''} ${item?.lastName ? item.lastName : ''}`}
            </Link>
        )
    },
    {
        header: 'Role',
        accessor: 'roles'
    },
    {
        header: 'Joining Date',
        accessor: 'createdAt',
        content: (item: any) => <Typography>{new Date(item.createdAt).toLocaleDateString()} </Typography>
    },
    {
        header: 'Status',
        content: (item: any) => (
            <>
                {item.isActive ? (
                    <Typography sx={{ color: '#44bd32' }}>{langString('active')} </Typography>
                ) : (
                    <Typography sx={{ color: '#c23616' }}>{langString('inavtive')} </Typography>
                )}
            </>
        )
    },
    {
        header: 'Target',
        accessor: 'targetValue',
        content: (item: any) => (
            <input
                style={{
                    width: '50%',
                    textAlign: 'center',
                    padding: ' 2px 5px',
                    border: 0,
                    backgroundColor: 'lightgray',
                    borderRadius: '5px'
                }}
                onChange={(e) => setTargetOnChange({ e, userList, item, dispatch })}
                onBlur={async (e) => setTargetOnBlur({ e, formId, item, snackbarMsg })}
                value={item.targetValue}
            />
        )
    },
    { header: 'Total Submission', accessor: 'totalSubmission' }
];

export const profileBtnStyle = { width: '100%', padding: '3%' };

export const AUTO_TARGET_DISTRIBUTION = 'auto_target_distribution';
export const TARGET_TO_MULTIPLE_USER = 'target_to_multiple_user';

export const randomText = (length: any) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    /* eslint-disable no-plusplus */
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const tableBorder = {
    border: '1px solid lightgrey'
};

export const targetCancelBtn = { backgroundColor: 'lightgray', color: 'black' };
