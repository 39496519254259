import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DateRangePicker, { DateRange } from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Box, Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';
import { gridSpacing } from 'store/constant';
import langString from 'utils/langString';

type ProjectFormsFilterPropsType = {
    dateRange: DateRange<Date>;
    setDateRange: (value: DateRange<Date>) => void;
    published: boolean;
    setPublished: (value: boolean) => void;
    active: boolean;
    setActive: (value: boolean) => void;
    name: string;
    setName: (value: string) => void;
};
export default function ProjectFormsFilter({
    dateRange,
    setDateRange,
    published,
    setPublished,
    active,
    setActive,
    name,
    setName
}: ProjectFormsFilterPropsType) {
    return (
        <>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12} sm={5}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateRangePicker
                            startText="Start"
                            endText="End"
                            value={dateRange}
                            // eslint-disable-next-line @typescript-eslint/no-implied-eval
                            onChange={(newValue) => setDateRange(newValue)}
                            renderInput={(startProps, endProps) => (
                                <>
                                    <TextField size="small" {...startProps} />
                                    <Box sx={{ mx: 2 }}> to </Box>
                                    <TextField size="small" {...endProps} />
                                </>
                            )}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={published}
                                onChange={(event) => setPublished(event.target.checked)}
                                name="published"
                                color="primary"
                            />
                        }
                        label={langString('published')}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={active}
                                onChange={(event) => setActive(event.target.checked)}
                                name="active"
                                color="primary"
                            />
                        }
                        label={langString('active')}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <TextField
                        size="small"
                        id="outlined-select-name"
                        fullWidth
                        label={langString('formname')}
                        value={name}
                        onChange={(event: any) => setName(event.target.value)}
                    />
                </Grid>
                {/* <Grid item xs={12} sm={1}>
                    <Button variant="contained" color="primary">
                        {langString('filter')}
                    </Button>
                </Grid> */}
            </Grid>
        </>
    );
}
