import { useEffect, useState } from 'react';
import { Autocomplete, Button, Checkbox, FormControl, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import useSnackbar from 'hooks/useSnackbar';
import { CURRENT_PAGE, PAGE_SIZE } from 'utils/Constants';
import { icon, checkedIcon } from 'views/TeamManagement/UserManagement/Constants';
import UserFormTable from './UserFormTable';
import {
    createUserForm,
    fetchProjectFormsList,
    fetchTeamMembersList,
    getUserFormList,
    userFormListUpdate,
    userFormDelete
} from './Service';

export default function ProjectLocationMembers({ projectId, areaId }: any) {
    const [data, setData] = useState<any | null>(null);
    const [userList, setUserList] = useState<any[]>([]);
    const [formList, setFormList] = useState<any[]>([]);
    const [selectedUserList, setSelectedUserList] = useState<any[]>([]);
    const [selectedFormList, setSelectedFormList] = useState<any[]>([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(CURRENT_PAGE);
    const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE);
    const [changeDetected, setChangeDetected] = useState(false);

    const snackbarMsg = useSnackbar();

    const fetchData = async () => {
        const teamMembers: any = await fetchTeamMembersList({ projectId });
        const userdata: any[] = [];
        if (teamMembers && teamMembers.count > 0) {
            teamMembers.data.forEach((item: any, index: number) => {
                userdata.push({
                    id: item.userId,
                    name: item.username
                });
            });
        }
        setUserList(userdata);

        const projectForms: any = await fetchProjectFormsList({ projectId });

        const formsdata: any[] = [];
        if (projectForms && projectForms.count > 0) {
            projectForms.data.forEach((item: any, index: number) => {
                formsdata.push({
                    id: item.id,
                    name: item.title
                });
            });
        }
        setFormList(formsdata);
        resetTableData();
    };

    const reset = () => {
        setSelectedUserList([]);
        setSelectedFormList([]);
        setChangeDetected(false);
    };

    const resetTableData = async () => {
        const userFormList: any = await getUserFormList({ projectId, areaId });
        setData(userFormList.data);
        setCount(userFormList.data.length);
    };

    const handleUserFormSubmit = async () => {
        const selectedUserIdList: any[] = [];
        selectedUserList.forEach((item: any, index: number) => {
            selectedUserIdList.push(item.id);
        });

        const selectedFormIdList: any[] = [];
        selectedFormList.forEach((item: any, index: number) => {
            selectedFormIdList.push(item.id);
        });
        const payload = { projectId, areaId, userIdList: selectedUserIdList, formIdList: selectedFormIdList };
        await createUserForm(payload, snackbarMsg);
        resetTableData();
        reset();
    };

    const handleUpdate = async () => {
        await userFormListUpdate(data, snackbarMsg);
        resetTableData();
        reset();
    };

    const handleDelete = async (objId: number) => {
        await userFormDelete({ id: objId }, snackbarMsg);
        resetTableData();
        reset();
    };

    useEffect(() => {
        if (!(projectId && areaId)) {
            setUserList([]);
            setFormList([]);
            setData([]);
            setCount(0);
        } else {
            fetchData();
        }
        setChangeDetected(false);
        reset();
    }, [projectId, areaId]);

    return (
        <>
            <Grid container spacing={2} style={{ marginTop: '10px' }}>
                <Grid item sm={4}>
                    <FormControl fullWidth>
                        <Typography variant="h5">Select Team Members</Typography>
                        <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            options={userList}
                            onChange={(e, value) => {
                                setSelectedUserList(value);
                            }}
                            value={selectedUserList}
                            disableCloseOnSelect
                            getOptionLabel={(option: any) => option.name}
                            renderOption={(props, option: any, { selected }) => (
                                <li {...props}>
                                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                    {option.name}
                                </li>
                            )}
                            renderInput={(params: any) => (
                                // <TextField {...params} onBlur={formik.handleBlur} error={formik.touched.role && Boolean(formik.errors.role)} />
                                <TextField {...params} />
                            )}
                        />
                        <FormHelperText style={{ color: 'red' }}>
                            {/* {formik.errors.role && formik.touched.role && formik.errors.role} */}
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item sm={4}>
                    <FormControl fullWidth>
                        <Typography variant="h5">Select Form</Typography>
                        <Autocomplete
                            multiple
                            id="checkboxes-tags-demo"
                            options={formList}
                            onChange={(e, value) => {
                                setSelectedFormList(value);
                            }}
                            value={selectedFormList}
                            disableCloseOnSelect
                            getOptionLabel={(option: any) => option.name}
                            renderOption={(props, option: any, { selected }) => (
                                <li {...props}>
                                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                    {option.name}
                                </li>
                            )}
                            renderInput={(params: any) => (
                                // <TextField {...params} onBlur={formik.handleBlur} error={formik.touched.role && Boolean(formik.errors.role)} />
                                <TextField {...params} />
                            )}
                        />
                        <FormHelperText style={{ color: 'red' }}>
                            {/* {formik.errors.role && formik.touched.role && formik.errors.role} */}
                        </FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item sm={4} style={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                        variant="contained"
                        type="submit"
                        onClick={handleUserFormSubmit}
                        disabled={selectedUserList.length === 0 || selectedFormList.length === 0 ? true : false}
                    >
                        Generate
                    </Button>
                </Grid>
            </Grid>
            <UserFormTable
                changeDetected={changeDetected}
                setChangeDetected={setChangeDetected}
                handleSubmit={handleUpdate}
                handleDelete={handleDelete}
                data={data}
                setData={setData}
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                setPage={(value: number) => setPage(value)}
                setRowsPerPage={(value: number) => setRowsPerPage(value)}
                showSL
            />
        </>
    );
}
