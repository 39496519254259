import SettingsIcon from '@mui/icons-material/Settings';
import { Checkbox, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useState } from 'react';
import { tableBorder, targetCancelBtn } from './constants';

export default function TargetDialog({ users, setTargetUsers, open, setOpen, save }: any) {
    const [target, setTarget] = useState();
    return (
        <div>
            <Dialog open={open} maxWidth="lg" onClose={() => setOpen(false)}>
                <DialogTitle style={{ borderBottom: '1px solid lightgrey', marginBottom: '2%' }}>
                    {' '}
                    <Button startIcon={<SettingsIcon />}>Set Target To Multiple Users</Button>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6} style={{ border: '1px solid lightgrey', maxHeight: '500px', overflowY: 'scroll' }}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={tableBorder}>
                                                {' '}
                                                <Checkbox />{' '}
                                            </TableCell>
                                            <TableCell style={tableBorder}>Username</TableCell>
                                            <TableCell style={tableBorder}>Role</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {users.map((user: any, index: number) => (
                                            <TableRow key={user.userId}>
                                                <TableCell scope="row" style={tableBorder}>
                                                    <Checkbox onClick={() => setTargetUsers(user.id)} />
                                                </TableCell>
                                                <TableCell style={tableBorder}>{`${user.firstName ? user.firstName : ''} ${
                                                    user.lastName ? user.lastName : ''
                                                }`}</TableCell>
                                                <TableCell style={tableBorder}>{user.roles}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="h5">Target:</Typography>
                            <TextField fullWidth variant="outlined" onChange={(e: any) => setTarget(e.target.value)} placeholder="target" />
                            <FormHelperText>The entered number will be assigned as target to each selected user. </FormHelperText>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        style={targetCancelBtn}
                                        onClick={() => {
                                            setTargetUsers(-1);
                                            setOpen(false);
                                        }}
                                    >
                                        {' '}
                                        Cancel{' '}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={(e: any) => save(target)}>
                                        Save
                                    </Button>{' '}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
}
