import { isEmpty } from 'utils/utilities';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AreaForm from './Form';
import { fetchDataDetail } from './Service';

export default function EditArea() {
    const [data, setData] = useState<any | null>(null);
    const identifier = useParams().areaId;

    const dataLoader = async () => {
        const detailData = await fetchDataDetail({ id: identifier });
        if (!isEmpty(detailData)) {
            setData(detailData);
        }
    };

    useEffect(() => {
        if (identifier) {
            dataLoader();
        }
    }, []);

    return data ? <AreaForm info={data} /> : null;
}
