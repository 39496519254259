import { combineReducers } from 'redux';

// reducer import
import accountReducer from './accountReducer';
import customizationReducer from './customizationReducer';
import formDetailsReducer from './formDetailsReducer';
import formReducer from './formsReducer';
import itemReducer from './itemReducer';
import { notificationReducer } from './notificationReducer';
import { permissionReducer } from './permissionReducer';
import projectsReducer from './projectReducer';
import snackbarReducer from './snackbarReducer';
import categoryReducer from './categoryReducer';
import languageReducer from './languageReducer';
import categoryItemReducer from './categoryItemReducer';
import parentItemReducer from './parentReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    snackbar: snackbarReducer,
    account: accountReducer,
    projects: projectsReducer,
    form: formReducer,
    item: itemReducer,
    formDetails: formDetailsReducer,
    notification: notificationReducer,
    permission: permissionReducer,
    category: categoryReducer,
    language: languageReducer,
    categoryItem: categoryItemReducer,
    parent: parentItemReducer
});

export type TRootState = ReturnType<typeof reducer>;

export default reducer;
