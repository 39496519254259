/* eslint-disable import/prefer-default-export */
export const ProjetcsData = [
    {
        name: 'JANO',
        id: 1,
        startDate: new Date(),
        endDate: new Date('2025-05-15'),
        partner: 1,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
        active: true,
        published: false,
        archived: false
    },
    {
        name: 'IOM Gaziantep',
        id: 2,
        startDate: new Date(),
        endDate: new Date('2025-05-15'),
        partner: 3,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
        active: true,
        published: true,
        archived: false
    },
    {
        name: 'BAHIS',
        id: 3,
        startDate: new Date(),
        endDate: new Date('2025-05-15'),
        partner: 1,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
        active: true,
        published: true,
        archived: false
    },
    {
        name: 'NI NVAC',
        id: 4,
        startDate: new Date(),
        endDate: new Date('2025-05-15'),
        partner: 1,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
        active: true,
        published: true,
        archived: false
    },
    {
        name: 'USAID',
        id: 5,
        startDate: new Date(),
        endDate: new Date('2025-05-15'),
        partner: 2,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
        active: true,
        published: false,
        archived: false
    },
    {
        name: 'GIZ',
        id: 6,
        startDate: new Date(),
        endDate: new Date('2025-05-15'),
        partner: 1,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
        active: false,
        published: true,
        archived: true
    },
    {
        name: 'BHALO',
        id: 7,
        startDate: new Date(),
        endDate: new Date('2025-05-15'),
        partner: 3,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
        active: false,
        published: true,
        archived: true
    },
    {
        name: 'Sudokkho',
        id: 8,
        startDate: new Date(),
        endDate: new Date('2025-05-15'),
        partner: 1,
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
        active: false,
        published: true,
        archived: true
    }
];

export const PartnersData = [
    { id: 1, name: 'CARE' },
    { id: 2, name: 'PLAN' },
    { id: 3, name: 'BRAC' }
];

export const TeamMembersData = [
    { id: 1, username: 'User 1', organization: 'FAO', role: 'Data Collector' },
    { id: 2, username: 'User 2', organization: 'UNDP', role: 'Data Manager' },
    { id: 3, username: 'User 3', organization: 'USAID', role: 'Data Collector' }
];

export const ProjectReportsData = [
    { id: 1, name: 'Report 1', organization: 'FAO' },
    { id: 2, name: 'Report 2', organization: 'UNDP' },
    { id: 3, name: 'Report 3', organization: 'USAID' }
];

export const OrganizationsData = [
    { id: 1, name: 'FAO' },
    { id: 2, name: 'UNDP' },
    { id: 3, name: 'IOM' }
];

export const RolesData = [
    { id: 1, name: 'Data Collector' },
    { id: 2, name: 'Data Manager' },
    { id: 3, name: 'Field Worker' }
];

export const ModulesTypeData = [
    { id: 1, name: 'Container' },
    { id: 2, name: 'Form' },
    { id: 3, name: 'List' }
];

export const ProjectFormsCardData = {
    total_forms: 40,
    total_submissions: 8200,
    unique_surveyors: 38,
    total_active_forms: 6
};

export const ProjectFormsData = [
    {
        id: 1,
        name: 'Household Reg',
        creation_date: new Date(),
        active: true,
        published: false,
        surveyor: 5,
        target: 300,
        total_submissions: 200
    },
    {
        id: 2,
        name: 'Form-1',
        creation_date: new Date(),
        active: false,
        published: false,
        surveyor: 5,
        target: 140,
        total_submissions: 207
    },
    {
        id: 3,
        name: 'Form 2',
        creation_date: new Date(),
        active: true,
        published: true,
        surveyor: 5,
        target: 1300,
        total_submissions: 800
    },
    {
        id: 4,
        name: 'Form 4',
        creation_date: new Date(),
        active: true,
        published: false,
        surveyor: 5,
        target: 100,
        total_submissions: 82
    }
];

export const FormsData = [
    { id: 1, name: 'Form 8' },
    { id: 2, name: 'Form 9' },
    { id: 3, name: 'Form 10' }
];

export const DeployedFormsData = [
    { id: 1, name: 'Form 8', createdOn: new Date(), lastModified: new Date() },
    { id: 2, name: 'Form 9', createdOn: new Date(), lastModified: new Date() },
    { id: 3, name: 'Form 10', createdOn: new Date(), lastModified: new Date() },
    { id: 4, name: 'Form 11', createdOn: new Date(), lastModified: new Date() }
];

export const DraftsFormsData = [
    { id: 1, name: 'Form 8', createdOn: new Date(), lastModified: new Date() },
    { id: 2, name: 'Form 9', createdOn: new Date(), lastModified: new Date() },
    { id: 3, name: 'Form 10', createdOn: new Date(), lastModified: new Date() },
    { id: 4, name: 'Form 11', createdOn: new Date(), lastModified: new Date() }
];

export const FormsUserData = [
    {
        username: 'tanvir',
        role: 'admin',
        joining_date: new Date(),
        status: 'active',
        target: '100'
    },
    {
        username: 'hasan',
        role: 'data collector',
        joining_date: new Date(),
        active: true,
        target: '100'
    },
    {
        username: 'sahnewaz',
        role: 'accountant',
        joining_date: new Date(),
        status: 'active',
        target: '100'
    }
];
