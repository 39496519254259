import { CategoryItem } from 'views/MasterData/Constants';
// action type
export const CATEGORY_ITEM_ADD = 'category/item_add';

// type of action

type add_item = typeof CATEGORY_ITEM_ADD;

type CategoryActionTypes = add_item;

// action - state management

interface Items {
    categoryItems: CategoryItem[];
}

const initialState: Items = {
    categoryItems: []
};
// ==============================|| Projects REDUCER ||============================== //

export interface IProjectsReducerActionProps {
    type: CategoryActionTypes;
    payload?: CategoryItem | CategoryItem[];
}

const categoryItemReducer = (state = initialState, action: IProjectsReducerActionProps) => {
    switch (action.type) {
        case CATEGORY_ITEM_ADD: {
            console.log(action.payload);
            return {
                ...state,
                categoryItems: action.payload
            };
        }

        // case CATEGORY_DELETE: {
        //     state.categories = state.categories.filter((item) => item.categoryValue !== action.payload?.categoryValue);
        //     return {
        //         ...state
        //     };
        // }
        default: {
            return state;
        }
    }
};

export default categoryItemReducer;

export const addCategoryItem = (cat: CategoryItem[] | null) => ({
    type: CATEGORY_ITEM_ADD,
    payload: cat
});

// export const deleteCategory = (cat: Category | null) => ({
//     type: CATEGORY_DELETE,
//     payload: cat
// });
