import { Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUsers, setUsers } from 'store/formsReducer';

export default function EditTable() {
    const { users, currentState } = useSelector((state: any) => state?.form?.importForm);
    const dispatch = useDispatch();

    if (typeof currentState.users === 'undefined') return null;

    return (
        <TableContainer>
            <Typography variant="h3" gutterBottom textAlign="center">
                User Table
            </Typography>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ border: '1px solid lightgrey' }}>
                            Username
                        </TableCell>

                        <TableCell style={{ border: '1px solid lightgrey' }}>Role</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {currentState.users.map((user: any, index: number) => (
                        <TableRow key={user?.id}>
                            <TableCell scope="row" style={{ border: '1px solid lightgrey' }}>
                                {`${user?.firstName ? user?.firstName : ''} ${user?.lastName ? user?.lastName : ''}`}
                            </TableCell>

                            <TableCell style={{ border: '1px solid lightgrey' }}>
                                {user?.roles?.[0].name ? user?.roles?.[0].name : ''}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
