import axiosServices from 'utils/axiosServices';
import { _deserailize } from 'utils/Deserialize';
import { errMsg } from 'utils/Helpers';
import {
    getAllFormConfigurationURL,
    projectAreaDetailURL,
    projectAreaUpdateURL,
    projectTeamMemberURL,
    locationUserFormCreateURL,
    locationUserFormListURL,
    locationUserFormUpdateURL,
    locationUserFormDeleteURL
} from 'utils/serverUrls';

export const fetchTeamMembersList = async ({ projectId }: any) => {
    const params = {
        projectId,
        currentPage: 1,
        pageSize: 10000000
    };

    try {
        const response = await axiosServices.get(`${projectTeamMemberURL}`, { params });
        const data = await _deserailize(response.data);
        return await new Promise((resolve, _) => {
            resolve({ data, count: response?.data?.meta?.count });
        });
    } catch (ex) {
        return await new Promise((resolve, _) => {
            resolve({ data: [], count: 0 });
        });
    }
};

export const fetchProjectFormsList = async ({ projectId }: any) => {
    const params = {
        projectId,
        currentPage: 1,
        pageSize: 10000000,
        isPublished: true,
        isActive: true
    };

    try {
        const response = await axiosServices.post(`${getAllFormConfigurationURL}`, { ...params });
        const data = await _deserailize(response.data);
        return await new Promise((resolve, _) => {
            resolve({ data, count: response?.data?.meta?.count });
        });
    } catch (ex) {
        return await new Promise((resolve, _) => {
            resolve({ data: [], count: 0 });
        });
    }
};

export const fetchLocationDataDetail = async (body: any) => {
    try {
        const response = await axiosServices.post(`${projectAreaDetailURL}`, { ...body });
        const data = await response.data;
        return await new Promise((r, _) => r({ data }));
    } catch (ex: any) {
        return await new Promise((r, _) => r({}));
    }
};

export const editProjectArea = async (payload: any, snackbarMsg: any) => {
    try {
        await axiosServices.post(`${projectAreaUpdateURL}`, payload);
        snackbarMsg('Location Configuration Updated successfully.');
        return await new Promise((r, _) => r({}));
    } catch (ex: any) {
        snackbarMsg(errMsg(ex), 'error');
        return await new Promise((r, _) => r({}));
    }
};

export const createUserForm = async (payload: any, snackbarMsg: any) => {
    try {
        await axiosServices.post(`${locationUserFormCreateURL}`, payload);
        snackbarMsg('New user-form added.');
        return await new Promise((r, _) => r({}));
    } catch (ex: any) {
        snackbarMsg(errMsg(ex), 'error');
        return await new Promise((r, _) => r({}));
    }
};

export const getUserFormList = async (payload: any) => {
    try {
        const response = await axiosServices.post(`${locationUserFormListURL}`, { ...payload });
        const data = await response.data;
        return await new Promise((r, _) => r({ data }));
    } catch (ex: any) {
        return await new Promise((r, _) => r({}));
    }
};

export const userFormListUpdate = async (payload: any, snackbarMsg: any) => {
    try {
        await axiosServices.post(`${locationUserFormUpdateURL}`, payload);
        snackbarMsg('Updated.');
        return await new Promise((r, _) => r({}));
    } catch (ex: any) {
        snackbarMsg(errMsg(ex), 'error');
        return await new Promise((r, _) => r({}));
    }
};

export const userFormDelete = async (payload: any, snackbarMsg: any) => {
    try {
        await axiosServices.post(`${locationUserFormDeleteURL}`, payload);
        snackbarMsg('Deleted.', 'error');
        return await new Promise((r, _) => r({}));
    } catch (ex: any) {
        snackbarMsg(errMsg(ex), 'error');
        return await new Promise((r, _) => r({}));
    }
};

export default {};
