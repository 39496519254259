import axiosServices from 'utils/axiosServices';
import { errMsg } from 'utils/Helpers';
import { resetPasswordURL } from 'utils/serverUrls';
import { FAILED, LOADING, SUCCESS } from './auth-forms/RegisterForm/NewPassword';

export const forgotPassword = async ({ values, resetPassword, snackbarMsg, navigate }: any) => {
    try {
        window.localStorage.clear();
        window.sessionStorage.clear();
        await resetPassword(values.email);
        snackbarMsg('Password reset link sent to your email', 'success');
        navigate('/login', { replace: true });
    } catch (err: any) {
        snackbarMsg(errMsg(err), 'error');
    }
};

export const setPassword = async ({ values, id, setStage }: any) => {
    setStage(LOADING);
    try {
        await axiosServices.post(resetPasswordURL, {
            id,
            password: values.password
        });
        setStage(SUCCESS);
    } catch (error) {
        setStage(FAILED);
    }
};
