import AddBoxIcon from '@mui/icons-material/AddBox';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import langString from 'utils/langString';

type WrapperPropsType = {
    title: string;
    icon?: React.ReactNode;
    backLink?: string;
    addLink?: string;
    actionBtn?: React.ReactNode;
    children: React.ReactNode;
};

export default function Wrapper({ title, icon, backLink, addLink, children, actionBtn }: WrapperPropsType) {
    return (
        <Box sx={{ minHeight: '80vh' }}>
            <Grid container sx={{ marginBottom: 1 }}>
                <Grid item xs={8} sm={8} lg={8} xl={8}>
                    {icon && <IconButton sx={{ display: 'inline', cursor: 'default' }}>{icon}</IconButton>}
                    <Typography sx={{ color: '#000', fontSize: 18, fontWeight: 600, display: 'inline', marginLeft: 1 }}>{title}</Typography>
                </Grid>
                <Grid item xs={4} sm={4} lg={4} xl={4}>
                    {backLink && (
                        <Button sx={{ float: 'right' }} size="small" component={Link} to={backLink} variant="contained" color="info">
                            <ArrowBackIosIcon />
                            {langString('back')}
                        </Button>
                    )}
                    {addLink && (
                        <Button sx={{ float: 'right' }} size="small" component={Link} to={addLink} variant="contained" color="primary">
                            <AddBoxIcon />
                            {langString('add')}
                        </Button>
                    )}
                    {actionBtn}
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12} sm={12} lg={12} xl={12} sx={{ padding: 1 }}>
                    {children}
                </Grid>
            </Grid>
        </Box>
    );
}
