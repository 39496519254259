import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SNACKBAR_OPEN } from "store/actions";

interface SnackbarMsg {
    message: string;
    severity: string;
}

const defaultSnackbarMsg: SnackbarMsg = {
    message: '',
    severity: 'success',
}

export default function useSnackbar() {

    const [snackbar, setSnackbar] = useState<SnackbarMsg>(defaultSnackbarMsg);
    const dispatch = useDispatch();

    const setSnackbarMsg = (message: string, severity: string = 'success') => {
        setSnackbar({ message, severity });
    }

    useEffect(() => {
        if (snackbar.message.length > 0) {
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: snackbar.message,
                variant: 'alert',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                alertSeverity: snackbar.severity,
                transition: 'Fade'
            });
        }
    }, [snackbar]);

    return setSnackbarMsg;

}