import { setCurrentUserForAddUser, setUsersForAddUser } from 'store/formsReducer';
import axiosServices from 'utils/axiosServices';
import { _deserailize } from 'utils/Deserialize';
import { errMsg } from 'utils/Helpers';
import { createFormUserURL, getAddFormUserListURL } from 'utils/serverUrls';

export const init = async ({
    projectId,
    formId,
    org_id,
    role_id,
    username,
    pageSize,
    currentPage,
    snackbarMsg,
    dispatch,
    setCount
}: any) => {
    try {
        const body = {
            projectId,
            formId,
            org_id,
            role_id,
            username,
            pageSize,
            currentPage
        };

        if (org_id.length === 0) {
            delete body.org_id;
        }
        if (role_id.length === 0) {
            delete body.role_id;
        }

        const response = await axiosServices.post(`${getAddFormUserListURL}`, { ...body });

        const data = await _deserailize(response.data);

        const users = data.map((item: any, index: number) => ({
            ...item,
            sl: index + 1,
            isChecked: false
        }));

        dispatch(setUsersForAddUser(users));
        setCount(response?.data?.meta?.count);
        return await new Promise((r, _) => r({ data, count: response?.data?.meta?.count }));
    } catch (ex) {
        snackbarMsg(errMsg(ex), 'error');
        dispatch(setUsersForAddUser(null));
        return await new Promise((r, _) => r({ data: [], count: 0 }));
    }
};

export const onSubmit = async ({ formId, currentUser, snackbarMsg, handleClose, reload, dispatch }: any) => {
    try {
        if (currentUser?.length > 0) {
            const body = {
                formId,
                userIds: currentUser?.map((item: any) => parseInt(item.id, 10))
            };

            await axiosServices.post(`${createFormUserURL}`, { ...body });
            dispatch(setCurrentUserForAddUser([]));
            snackbarMsg('User added successfully', 'success');
            handleClose();
            reload();
        } else {
            snackbarMsg('Please select user', 'error');
        }
    } catch (ex) {
        snackbarMsg(errMsg(ex), 'error');
        handleClose();
    }
};
