import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, FormControl, InputLabel, Button } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import LineMap from 'components/Map/LineMap';
import { FilterTypes, filterInitialValue } from './Constants';
import { fetchDataDetail, fetchDataList } from '../Service';
import { editProjectArea, fetchLocationDataDetail } from './Service';
import { useSelector } from 'react-redux';
import useSnackbar from 'hooks/useSnackbar';
import useQuery from 'hooks/useQuery';
import ProjectLocationMembers from './ProjectLocationMembers';

export default function LocationConfiguration() {
    const [finalPolyline, setFinalPolyline] = useState([]);
    const [finalPolygonList, setFinalPolygonList] = useState<any[]>([]);
    const [dataList, setDataList] = useState<any>([]);
    const [areaList, setAreaList] = useState<any>([]);
    const [selectedArea, setSelectedArea] = useState('');
    const [filters, setFilters] = useState<FilterTypes>(filterInitialValue);
    const [polygonCount, setPolygonCount] = useState(0);
    const [completePolygonCount, setCompletePolygonCount] = useState(0);
    const [changeDetected, setChangeDetected] = useState(true);

    const account: any = useSelector((state: any) => state.account);
    const snackbarMsg = useSnackbar();
    const { area } = useQuery('area');
    const projectId = useParams().id;

    const fetchFilterOptions = async () => {
        const params = {
            orgId: account.user?.organization?.id,
            pageSize: 1000000,
            currentPage: 1
        };
        const filterData: any = await fetchDataList(params, snackbarMsg);
        if (filterData && 'data' in filterData) {
            setAreaList(filterData.data);
            if (area) {
                setFilters({ areaId: area });
                updateMapView(area);
            }
        }
    };

    const fetchAreaData = async (identifier: any) => {
        const detailData: any = await fetchDataDetail({ id: identifier });
        setDataList(detailData.data.polygon);
        const locationDetailData = (await fetchLocationDataDetail({ areaId: identifier, projectId })) as { data: { polygonList: any[] } };
        if ('data' in locationDetailData) {
            setFinalPolygonList([...locationDetailData.data.polygonList, []]);
        } else {
            setFinalPolygonList([]);
        }

        setChangeDetected(false);
    };

    const updateMapView = (areaId: any) => {
        setSelectedArea(areaId);
        if (areaId !== '') {
            fetchAreaData(areaId);
        } else {
            setDataList([]);
            setFinalPolygonList([]);
        }
    };

    const filterPolygonList = () => {
        const list: any[] = [];
        for (const polygon of finalPolygonList as any[]) {
            if (polygon.length > 0) {
                list.push(polygon);
            }
        }
        return list;
    };

    const handlePolygonSubmission = async () => {
        const finalFilteredPolygonList = filterPolygonList();
        const payload = {
            projectId,
            areaId: selectedArea,
            polygonList: finalFilteredPolygonList
        };
        console.log('payload ===>> ', payload);
        const detailData: any = await editProjectArea(payload, snackbarMsg);
        setChangeDetected(false);
    };

    useEffect(() => {
        let totalCompletePolygons = 0;
        let totalPolygons = 0;
        for (const polygon of finalPolygonList as any[]) {
            if (
                polygon.length > 3 &&
                polygon[0][0] === polygon[polygon.length - 1][0] &&
                polygon[0][1] === polygon[polygon.length - 1][1]
            ) {
                totalCompletePolygons += 1;
            }

            if (polygon.length > 0) {
                totalPolygons += 1;
            }
        }
        setCompletePolygonCount(totalCompletePolygons);
        setPolygonCount(totalPolygons);
    }, [finalPolygonList]);

    useEffect(() => {
        fetchFilterOptions();
    }, []);

    return (
        <>
            <Grid container spacing={2} justifyContent="space-between">
                <Grid item sm={2}>
                    <FormControl sx={{ width: '100%' }}>
                        <InputLabel>Select Area</InputLabel>
                        <Select
                            fullWidth
                            label="Role"
                            placeholder="Role"
                            name="roleId"
                            value={filters.areaId}
                            onChange={(e: any) => {
                                setFilters({ areaId: e.target.value });
                                updateMapView(e.target.value);
                            }}
                        >
                            <MenuItem value="">None</MenuItem>
                            {areaList.map((item: any) => (
                                <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <LineMap
                        setFinalPolyline={setFinalPolyline}
                        polygonList={finalPolygonList}
                        setPolygonList={setFinalPolygonList}
                        setChangeDetected={setChangeDetected}
                        initValues={dataList}
                        search={false}
                        editMain={false}
                        locConfig={true}
                    />
                </Grid>
                <Grid container spacing={2} direction="row-reverse" style={{ marginTop: '10px' }}>
                    <Grid item>
                        <Button
                            variant="contained"
                            onClick={handlePolygonSubmission}
                            disabled={changeDetected && completePolygonCount > 0 && polygonCount === completePolygonCount ? false : true}
                        >
                            Update Survey Areas
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            {selectedArea !== '' && <ProjectLocationMembers projectId={projectId} areaId={selectedArea} />}
        </>
    );
}
