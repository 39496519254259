import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, Checkbox, FormControl, Grid, InputAdornment, OutlinedInput, TextField, Typography } from '@mui/material';
import { Serializer } from 'jsonapi-serializer';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setCurrentFilterInUserForAddUser, setUserFilterForAddUser } from 'store/formsReducer';
import axiosServices from 'utils/axiosServices';
import { _deserailize } from 'utils/Deserialize';
import langString from 'utils/langString';
import { getAllByOrgsURL, getProjectOrganizationsURL } from 'utils/serverUrls';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function Filter() {
    const { filter } = useSelector((state: any) => state?.form?.addUser);
    const dispatch = useDispatch();
    const { projectId } = useParams();

    useEffect(() => {
        (async () => {
            try {
                const orgsRes = await axiosServices.get(`${getProjectOrganizationsURL}/?id=${projectId}`);
                const orgsList = await _deserailize(orgsRes.data);

                dispatch(setUserFilterForAddUser({ organizations: orgsList }));
            } catch (e) {
                console.log(e);
            }
        })();
    }, []);

    const onOrganizationChange = async (_: any, value: any) => {
        dispatch(setCurrentFilterInUserForAddUser({ organizations: value }));
        dispatch(setCurrentFilterInUserForAddUser({ roles: [] }));

        try {
            const body = {
                orgList: [...value.map((org: any) => parseInt(org?.orgId, 10))]
            };

            const serialize = await new Serializer('role', {
                keyForAttribute: 'camelCase',
                attributes: ['orgList']
            }).serialize(body);

            const roleRes = await axiosServices.post(getAllByOrgsURL, { ...serialize });
            const roleList = await _deserailize(roleRes.data);

            dispatch(setUserFilterForAddUser({ roles: roleList }));
        } catch (e) {
            console.log(e);
        }
    };
    const onRoleChange = async (_: any, value: any) => {
        dispatch(setCurrentFilterInUserForAddUser({ roles: value }));
    };
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
                <Typography variant="h6">{`${langString('organization')} `}</Typography>
                <FormControl fullWidth>
                    <Autocomplete
                        multiple
                        limitTags={2}
                        id="checkboxes-tags-organization"
                        disableCloseOnSelect
                        options={filter?.organizations}
                        value={filter?.current?.organizations}
                        getOptionLabel={(option: any) => option.orgName}
                        renderOption={(props: any, option: any, { selected }: any) => (
                            <li {...props}>
                                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                {option.orgName}
                            </li>
                        )}
                        onChange={onOrganizationChange}
                        style={{ width: '100%' }}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
                <Typography variant="h6">{langString('role')}</Typography>
                <FormControl fullWidth>
                    <Autocomplete
                        multiple
                        limitTags={2}
                        disableCloseOnSelect
                        id="checkboxes-tags-role"
                        options={filter?.roles}
                        value={typeof filter?.current?.roles === 'undefined' ? [] : filter?.current?.roles}
                        getOptionLabel={(option: any) => option.name}
                        renderOption={(props: any, option: any, { selected }: any) => (
                            <li {...props}>
                                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                {option.name}
                            </li>
                        )}
                        onChange={onRoleChange}
                        style={{ width: '100%' }}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                </FormControl>
            </Grid>

            <Grid item sm={3}>
                <Typography variant="h6">{`${langString('username')} `}</Typography>
                <OutlinedInput
                    id="outlined-adornment-weight"
                    name="username"
                    value={filter?.current?.username}
                    onChange={(e: any) => dispatch(setCurrentFilterInUserForAddUser({ userName: e.target.value }))}
                    endAdornment={
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    }
                />
            </Grid>
        </Grid>
    );
}
