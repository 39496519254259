/* eslint no-use-before-define: 0 */
import { Deserializer } from 'jsonapi-serializer';

/* eslint no-underscore-dangle: 0 */
export const _deserailize = (data: any): any => (
    new Deserializer({
        keyForAttribute: 'camelCase',
    }).deserialize(data)
)

export default {};