// ------------------------------ These API list associated with form --------------------------------
export const formInstanceUploadURL = `msurvey/forms/instance/submit/`;
export const formExportListURL = `msurvey/forms/export-list/`;
export const formExportCreateURL = `msurvey/forms/create-export/`;
export const formsInstanceValueListURL = `/msurvey/forms/instance-value/list/`;
export const formAttributesURL = `/msurvey/forms/form-attributes/`;
export const approvalUpdateURL = `/msurvey/forms/instance/approval/update/`;
export const formsDownloadURL = `/msurvey/forms/download/`;
export const formInstanceSubmitURL = `/msurvey/forms/instance/submit/`;
export const formCloneURL = `/msurvey/forms/clone/`;
export const formExportStatusURL = `/msurvey/forms/export-status/`;

// ----------------------------- Application API ----------------------------------------------------
export const loginURL = `msurvey/api/auth/login`;
export const sectorURL = `msurvey/api/master/sector/get-all`;
export const countryURL = `msurvey/api/master/country/get-all`;
export const signupURL = `msurvey/api/sign-up/create`;
export const privilegeURL = `msurvey/api/master/privilege/get-all`;
export const roleCreateURL = `msurvey/api/role/create`;
export const roleEditURL = `msurvey/api/role/update`;
export const roleListURL = `msurvey/api/role/get-all-by-org`;
export const roleBydIdURL = `msurvey/api/role/get-by-id`;
export const approveSignupURL = `msurvey/api/sign-up/approve`;
export const organizationChildListURL = `/msurvey/api/organization/get-all-child`;
export const organizationListURL = `/msurvey/api/organization/get-all`;
export const organizationParentListURL = `msurvey/api/organization/get-all-parent`;
export const organizationCreateURL = `msurvey/api/organization/create`;
export const organizationUpdateURL = `msurvey/api/organization/update`;
export const organizationDeleteURL = `msurvey/api/organization/delete`;
export const userCreateURL = `msurvey/api/user/create`;
export const userUpdateURL = `msurvey/api/user/update`;
export const userProfileURL = `msurvey/api/user/get-user-by-username`;
export const userProfileByKCIdURL = `msurvey/api/user/get-user-by-kcid`;
export const getUserByIdURL = `/msurvey/api/user/get-user-by-id`;
export const userListURL = `msurvey/api/user/get-user-by-org`;
export const userDeleteURL = `msurvey/api/user/delete`;
export const projectListURL = `msurvey/api/project/get-project-all`;
export const getProjectByOrgIdURL = `msurvey/api/project/get-project-by-orgId`;
export const projectAddURL = `msurvey/api/project/create`;
export const projectUpdateURL = `msurvey/api/project/update`;
export const projectGetURL = `msurvey/api/project/get-project-by-name-or-id`;
export const projectOrgListURL = `msurvey/api/project/get-project-organizations`;
export const projectTeamMemberURL = `msurvey/api/project/teams/get-project-users`;
export const projectPartnerUsersURL = `msurvey/api/project/teams/get-partner-org-users`;
export const removeProjectUserURL = `msurvey/api/project/teams/remove-user`;
export const addProjectUserURL = `msurvey/api/project/teams/add`;
export const projectUsersURL = `msurvey/api/project/teams/get-project-users`;
export const deleteProjectUserURL = `msurvey/api/project/teams/remove-user`;
export const projectFormUploadURL = `msurvey/forms/submit/`;
export const projectFormReplaceURL = `msurvey/forms/change/submit/`;
export const formListURL = `msurvey/api/project/forms/get-form-list-all`;
export const getAllFormConfigurationURL = `msurvey/api/project/forms/get-all-form-configuration`;
export const changeStatusURL = `msurvey/api/project/forms/change-status`;
export const getDashboardCountURL = `msurvey/api/project/forms/get-dashboard-count`;
export const createOrUpdateFormTargetURL = `msurvey/api/project/target-setting/create-or-update-form-target`;
export const settingsUpdateURL = `msurvey/api/project/settings-update`;
export const formsMediaUploadURL = `msurvey/forms/media/submit/`;
export const refreshTokenURL = `msurvey/api/auth/refresh-token`;
export const setUserTargetManualURL = `msurvey/api/project/target-setting/create-manual-user-target`;
export const autoUserTargetURL = `msurvey/api/project/target-setting/create-auto-user-target`;
export const getProjectOrganizationsURL = `/msurvey/api/project/get-project-organizations`;
export const getAllByOrgsURL = `/msurvey/api/role/get-all-by-orgs`;
export const getUserByOrgsAndRolesURL = `/msurvey/api/user/get-user-by-orgs-roles`;
export const templateListURL = `msurvey/api/project/forms/template-list`;
export const formsCloneURL = `/msurvey/forms/clone/`;
export const getUserListURL = `/msurvey/api/project/forms/get-users-list`;
export const createManualUserTargetURL = `/msurvey/api/project/target-setting/create-manual-user-target`;
export const getTargetDashboardCountURL = `/msurvey/api/project/forms/get-target-dashboard-count`;
export const updateFormsUserList = `/msurvey/api/project/forms/update-forms-users-list`;
export const getUserDashboardCountURL = `/msurvey/api/project/forms/get-user-target-dashboard-count`;
export const getFormByFormIdURL = `/msurvey/api/project/forms/get-form-by-formId`;
export const revertInstanceCreateURL = `/msurvey/api/project/forms/revert-instance-create`;
export const formsDeleteURL = `/msurvey/api/project/forms/delete`;
export const resetPasswordRequestURL = `/msurvey/api/user/reset-password-request`;
export const resetPasswordURL = `/msurvey/api/user/reset-password`;
export const saveColumnSettingsURL = `/msurvey/api/project/forms/update-columns-setting`;
export const getColumnSettingsURL = `/msurvey/api/project/forms/get-columns-setting`;
export const getAddFormUserListURL = `/msurvey/api/project/forms/get-add-form-user-list`;
export const createFormUserURL = `/msurvey/api/project/forms/create-form-user`;
export const projectFormDetailsURL = `/msurvey/api/project/forms/project-form-details`;
export const projectFormUpdateURL = `/msurvey/api/project/forms/project-form-update`;
export const createJsonInitURL = `/msurvey/api/form-builder/create-json-init`;
export const getLanguageURL = `/mSurvey/api/language/get`;
export const getCategory = `mSurvey/api/category/get`;
export const addCategoryURL = `mSurvey/api/category/create`;
export const deleteCategoryURL = `mSurvey/api/category/delete`;
export const updateCategoryURL = `mSurvey/api/category/update`;
export const getItemURL = `mSurvey/api/master/item/get`;
export const getParentURL = `mSurvey/api/category/getParent`;
export const addItemURL = `mSurvey/api/master/item/create`;
export const deleteItemURL = `mSurvey/api/master/item/delete`;
export const getItemUpdateURL = `mSurvey/api/master/item/getParent`;
export const itemUpdateURL = `mSurvey/api/master/item/update`;
export const getIfMultilingualURL = `/msurvey/forms/language-list/`;

// area related urls
export const areaListURL = `msurvey/api/area/list/`;
export const areaCreateURL = `msurvey/api/area/create/`;
export const areaDetailURL = `msurvey/api/area/detail/`;
export const areaUpdateURL = `msurvey/api/area/update/`;
export const areaDeleteURL = `msurvey/api/area/delete/`;

// projectArea related urls
export const projectAreaDetailURL = `msurvey/api/area/project-area/detail/`;
export const projectAreaUpdateURL = `msurvey/api/area/project-area/update/`;

// locationUserForm related urls
export const locationUserFormCreateURL = `msurvey/api/area/project-area/user-form/create/`;
export const locationUserFormListURL = `msurvey/api/area/project-area/user-form/list/`;
export const locationUserFormUpdateURL = `msurvey/api/area/project-area/user-form/update/`;
export const locationUserFormDeleteURL = `msurvey/api/area/project-area/user-form/delete/`;

export default {};
