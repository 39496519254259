import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArticleIcon from '@mui/icons-material/Article';
import SettingsIcon from '@mui/icons-material/Settings';
import { DateRange } from '@mui/lab/DateRangePicker';
import { Button, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { Box } from '@mui/system';
import useSnackbar from 'hooks/useSnackbar';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { setCurrentUser, setUserList } from 'store/formsReducer';
import MainCard from 'ui-component/cards/MainCard';
import SubCard from 'ui-component/cards/SubCard';
import Loading from 'ui-component/Loding';
import axiosServices from 'utils/axiosServices';
import { CURRENT_PAGE, PAGE_SIZE } from 'utils/Constants';
import { _deserailize } from 'utils/Deserialize';
import { autoUserTargetURL, getUserByIdURL, setUserTargetManualURL } from 'utils/serverUrls';

import FormProfileCard from './Card';
import { AUTO_TARGET_DISTRIBUTION, columns, profileBtnStyle, TARGET_TO_MULTIPLE_USER } from './constants';
import { fetchForm, fetchFormsProfiles, updateStatus } from './Service';
import FormUserTable from './Table';
import TargetDialog from './TargetDialog';
import FormUserFilter from './UserFilter';

export default function FormProfile() {
    const navigate = useNavigate();

    const [allUsers, setAllUsers] = useState<any[]>([]);
    const [dateRange, setDateRange] = useState<DateRange<Date>>([null, null]);
    const [published, setPublished] = useState(false);
    const [active, setActive] = useState(false);
    const [name, setName] = useState('');
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(CURRENT_PAGE);
    const [rowsPerPage, setRowsPerPage] = useState(PAGE_SIZE);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const {
        userList,
        current: { properties }
    } = useSelector((state: any) => state.form);

    const [targetDialog, setTargetDialog] = useState<boolean>(false);
    const [targetUsers, setTargetUsers] = useState<number[]>([]);
    const { projectId, formId, xformId } = useParams();

    const dispatch = useDispatch();
    const snackbarMsg = useSnackbar();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (targetType: string) => {
        setAnchorEl(null);
        setTargetPreference(targetType);
    };

    const init = async () => {
        const formProfile: any = await fetchFormsProfiles({
            fromDate: dateRange[0],
            toDate: dateRange[1],
            formId,
            userName: name,
            isActive: active,
            currentPage: page,
            pageSize: rowsPerPage,
            snackbarMsg,
            dispatch
        });

        setCount(parseInt(formProfile.count, 10));
        dispatch(setUserList(formProfile.data));

        setAllUsers(formProfile.data);
    };

    useEffect(() => {
        fetchForm({ dispatch, formId });
    }, [formId]);

    useEffect(() => {
        init();
    }, [formId, page, rowsPerPage, dateRange, name, active]);

    const goToDetails = () => {
        navigate(`/project/${projectId}/form/${formId}/${xformId}/details`);
    };

    const goToFormData = async (userId: any) => {
        const currentUserRes = await axiosServices.get(getUserByIdURL, { params: { id: userId } });
        const user = await _deserailize(currentUserRes.data);

        await new Promise((resolve, reject) => {
            dispatch(setCurrentUser(user));
            resolve({});
        });
        navigate(`/project/${projectId}/form/${formId}/data/${userId}`);
    };

    const setTargetPreference = (targettype: string) => {
        if (targettype === TARGET_TO_MULTIPLE_USER) setTargetDialog(true);
        else if (targettype === AUTO_TARGET_DISTRIBUTION) {
            (async () => {
                try {
                    await axiosServices.post(`${autoUserTargetURL}`, { formId, targetDate: '2020-06-06' });
                    snackbarMsg('Target distributed successfully');
                    init();
                } catch (ex) {
                    console.log(ex);
                }
            })();
        }
    };

    const setTemporaryTargetUsers = (userId: number) => {
        const users = _.cloneDeep(targetUsers);

        if (userId === -1) {
            setTargetUsers([]);
            return;
        }
        if (users.indexOf(userId) > -1) {
            users.splice(users.indexOf(userId), 1);
        } else {
            users.push(userId);
        }
        setTargetUsers(users);
    };

    const saveTargetUsers = async (targetValue: number) => {
        const payload = {
            targetSetting: {
                targetValue,
                formId
            },
            users: targetUsers
        };
        try {
            await axiosServices.post(`${setUserTargetManualURL}`, payload);
            snackbarMsg(' Target saved for multiple users');
            setTargetUsers([]);
            setTargetDialog(false);
            init();
        } catch (ex: any) {
            console.log('Server Error');
        }
    };

    const gotToNewSubmission = async () => {
        navigate(`/project/${projectId}/form/${formId}/new-submission`);
    };

    return (
        <Box>
            <Grid container justifyContent="space-between" alignItems="center" sx={{ marginBottom: 2 }}>
                <Grid item>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item>
                            <IconButton color="secondary" aria-label="projectType" component="span" sx={{ color: 'black' }}>
                                <ArticleIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            {/* @ts-ignore */}
                            <Typography variant="h4">{properties.title}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        {/* <Grid item>
                            <Button variant="contained" color="secondary" onClick={() => navigate(`/project/${1}/form/${formId}/settings`)}>
                                {' '}
                                Settings{' '}
                            </Button>
                        </Grid> */}
                        <Grid item>
                            <Button variant="contained" onClick={() => navigate(-1)}>
                                {' '}
                                Go Back{' '}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container>
                <FormProfileCard formId={formId || ''} />
            </Grid>

            <SubCard sx={{ marginTop: '3%' }}>
                <Grid container spacing={10}>
                    <Grid item xs={4}>
                        <Button variant="contained" color="primary" sx={profileBtnStyle} onClick={gotToNewSubmission}>
                            New Submission
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button variant="contained" color="primary" onClick={(e: any) => goToDetails()} sx={profileBtnStyle}>
                            Details
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            variant="contained"
                            style={{ backgroundColor: 'orange', display: 'flex', justifyContent: 'space-between', padding: '10px' }}
                            endIcon={<ArrowDropDownIcon />}
                            fullWidth
                        >
                            <Typography component="p" sx={{ whiteSpace: 'nowrap' }}>
                                Set Target{' '}
                            </Typography>
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button'
                            }}
                            sx={{ width: '100%' }}
                        >
                            <MenuItem sx={{ width: '100%' }} onClick={(e: any) => handleClose(AUTO_TARGET_DISTRIBUTION)}>
                                Auto Target Distribution
                            </MenuItem>
                            <MenuItem sx={{ width: '100%' }} onClick={(e: any) => handleClose(TARGET_TO_MULTIPLE_USER)}>
                                Set Target To Multiple Users
                            </MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            </SubCard>
            <Grid container justifyContent="space-between" alignItems="center" sx={{ marginTop: 5 }}>
                <Grid item xs={12} sx={{ paddingBottom: '1%' }}>
                    <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
                        <Grid item sx={{ width: '100 %' }}>
                            <Box display="flex" alignItems="center">
                                <SettingsIcon sx={{ color: 'black', paddingRight: 1, fontSize: 35 }} />
                                <Typography variant="h4">User List</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <MainCard>
                        <SubCard>
                            <FormUserFilter
                                dateRange={dateRange}
                                setDateRange={(value: DateRange<Date>) => setDateRange(value)}
                                published={published}
                                setPublished={(value: boolean) => setPublished(value)}
                                active={active}
                                setActive={(value: boolean) => setActive(value)}
                                name={name}
                                setName={(value: string) => setName(value)}
                                setTargetType={setTargetPreference}
                                reload={init}
                            />
                        </SubCard>
                        <br />
                        <SubCard>
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {typeof userList === 'undefined' ? (
                                <Loading />
                            ) : userList === null ? (
                                <Typography sx={{ marginX: 'auto', marginY: 2 }} variant="h3">
                                    Data not found
                                </Typography>
                            ) : (
                                <FormUserTable
                                    rows={userList}
                                    columns={columns({ formId, userList, dispatch, snackbarMsg, goToFormData })}
                                    count={count}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                    setPage={(value: number) => setPage(value)}
                                    setRowsPerPage={(value: number) => setRowsPerPage(value)}
                                    idField="id"
                                    statusField="active"
                                    updateStatus={(row: any) => updateStatus({ row, formId, userList, dispatch, snackbarMsg })}
                                />
                            )}
                        </SubCard>
                    </MainCard>
                    <TargetDialog
                        save={saveTargetUsers}
                        open={targetDialog}
                        setOpen={setTargetDialog}
                        users={allUsers}
                        setTargetUsers={setTemporaryTargetUsers}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
