import { Box, Grid, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gridSpacing } from 'store/constant';
import { setSummary } from 'store/formsReducer';
import axiosServices from 'utils/axiosServices';
import { _deserailize } from 'utils/Deserialize';
import langString from 'utils/langString';
import { getTargetDashboardCountURL } from 'utils/serverUrls';

type InfoCardPropsType = {
    bgColor: string;
    title: string;
    value: number;
};
const InfoCard = ({ title, value, bgColor }: InfoCardPropsType) => (
    <Grid item xs={12} sm={3}>
        <Box sx={{ background: bgColor, height: 100, padding: 2, color: '#FFF', borderRadius: 2 }}>
            <Typography sx={{ fontSize: 16, fontWeight: 600 }}>{title}</Typography>
            <Typography sx={{ fontSize: 30, fontWeight: 600, textAlign: 'right', marginTop: 1 }}>{value}</Typography>
        </Box>
    </Grid>
);
type ProjectFormsCardDataType = {
    formId: string;
};
export default function FormProfileCard({ formId }: ProjectFormsCardDataType) {
    const {
        current: { summary }
    } = useSelector((state: any) => state.form);
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            try {
                const response = await axiosServices.get(`${getTargetDashboardCountURL}?formId=${formId}`);
                const data = await _deserailize(response.data);
                dispatch(
                    setSummary({
                        totalTarget: data[0]?.totalTarget,
                        totalUnassignedTarget: data[0]?.totalUnassignedTarget,
                        totalAchivement: data[0]?.totalAchivement,
                        totalRemainedTarget: data[0]?.totalRemainedTarget
                    })
                );
            } catch (error) {
                dispatch(setSummary(null));
            }
        })();
    }, [formId]);

    return (
        <Grid container spacing={gridSpacing}>
            <InfoCard bgColor="#7183e1" title={langString('targets')} value={summary?.totalTarget} />
            <InfoCard bgColor="#3e8799" title={langString('unassigned targets')} value={summary?.totalUnassignedTarget} />
            <InfoCard bgColor="#72c946" title={langString('achievement')} value={summary?.totalAchivement} />
            <InfoCard bgColor="#e63f36" title={langString('remaining')} value={summary?.totalRemainedTarget} />
        </Grid>
    );
}
