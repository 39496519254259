// action type
export const PROJECT_MEMBER = 'item/projectMember';
export const ALL_MEMBER = 'item/allMember';

// ==============================|| type of action ||============================== //
type TProjectMember = typeof PROJECT_MEMBER;
type TAllMember = typeof ALL_MEMBER;

type TActionTypes = TProjectMember | TAllMember;

// ==============================|| action - state management ||============================== //
interface IItem {
    projectMember?: any[];
    allMember?: any[];
}

const initialState: IItem = {
    projectMember: undefined,
    allMember: undefined
};

// ==============================|| REDUCER ||============================== //

export interface IProjectsReducerActionProps {
    type: TActionTypes;
    payload?: IItem;
}

const itemReducer = (state = initialState, action: IProjectsReducerActionProps) => {
    switch (action.type) {
        case PROJECT_MEMBER: {
            return {
                ...state,
                ...action.payload
            };
        }
        case ALL_MEMBER: {
            return {
                ...state,
                ...action.payload
            };
        }
        default: {
            return state;
        }
    }
};

export default itemReducer;

// ==============================|| action creator ||============================== //

export const setProjectMember = (projectMember: any[] | null) => ({
    type: PROJECT_MEMBER,
    payload: { projectMember }
});

export const setAllMember = (allMember: any | null) => ({
    type: ALL_MEMBER,
    payload: { allMember }
});
