import { Category } from 'views/Projects/Details/Constants';

// action type
export const CATEGORY_ADD = 'category/add';
export const CATEGORY_UPDATE = 'category/update';
export const CATEGORY_DELETE = 'category/delete';

// type of action

type add_category = typeof CATEGORY_ADD;
type update_category = typeof CATEGORY_UPDATE;
type delete_category = typeof CATEGORY_DELETE;

type CategoryActionTypes = add_category | update_category | delete_category;

// action - state management

interface Categories {
    categories: Category[];
}

const initialState: Categories = {
    categories: []
};
// ==============================|| Projects REDUCER ||============================== //

export interface IProjectsReducerActionProps {
    type: CategoryActionTypes;
    payload?: Category | Category[];
}

const categoryReducer = (state = initialState, action: IProjectsReducerActionProps) => {
    switch (action.type) {
        case CATEGORY_ADD: {
            console.log(action.payload);
            return {
                ...state,
                categories: action.payload
            };
        }

        case CATEGORY_UPDATE: {
            console.log(action.payload);
            return {
                ...state,
                categories: [...state.categories, action.payload]
            };
        }

        // case CATEGORY_DELETE: {
        //     state.categories = state.categories.filter((item) => item.categoryValue !== action.payload?.categoryValue);
        //     return {
        //         ...state
        //     };
        // }
        default: {
            return state;
        }
    }
};

export default categoryReducer;

export const addCategory = (cat: Category[] | null) => ({
    type: CATEGORY_ADD,
    payload: cat
});

export const updateCategory = (cat: Category | null) => ({
    type: CATEGORY_UPDATE,
    payload: cat
});

// export const deleteCategory = (cat: Category | null) => ({
//     type: CATEGORY_DELETE,
//     payload: cat
// });
