import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { Button } from '@mui/material';
import ConfirmButton from './ConfirmButton';
import langString from 'utils/langString';
import * as yup from 'yup';

export const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
export const checkedIcon = <CheckBoxIcon fontSize="small" />;

export interface FilterTypes {
    organizationId: string;
    contactNo: string;
    roleId: string;
    createdDate: string | null;
    username: string;
}

export const userInitialValues = {
    username: '',
    email: '',
    password: '',
    firstName: '',
    contactNo: '',
    orgId: '',
    role: [],
    confirmPassword: ''
};

export const filterInitialValue: FilterTypes = {
    organizationId: '',
    contactNo: '',
    roleId: '',
    createdDate: null,
    username: ''
};

export const userValidationSchema = (isEdit: boolean) => {

    if (isEdit) {
        return yup.object().shape({
            username: yup.string().required('username is Required'),
            firstName: yup.string().required('first name is Required'),
            orgId: yup.string().required('Organization is Required'),
            contactNo: yup
                .string()
                .required('Number is required')
                .matches(/(^(\+8801|8801|01|008801))[1|1-9]{1}(\d){8}$/, 'Number is not valid'),
            email: yup
                .string()
                .required('Email is required')
                .matches(
                    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                    'Email is not valid'
                ),

            role: yup.mixed().test('role', 'Role is Required', (checkbox: any) => {
                let check = false;
                if (checkbox) {
                    for (const [key, value] of Object.entries(checkbox)) {
                        if (key && value && value !== '') {
                            check = true;
                            break;
                        }
                    }
                }

                return check;
            })
        });
    }
    return yup.object().shape({
        username: yup.string().required('username is Required'),
        firstName: yup.string().required('first name is Required'),
        orgId: yup.string().required('Organization is Required'),
        contactNo: yup
            .string()
            .required('Number is required')
            .matches(/(^(\+8801|8801|01|008801))[1|1-9]{1}(\d){8}$/, 'Number is not valid'),
        email: yup
            .string()
            .required('Email is required')
            .matches(
                /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                'Email is not valid'
            ),
        password: yup.string()
        .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.')
        .min(12).max(20).required('Password is Required')
        .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,"Must Contain 12 Characters, One Uppercase, One Lowercase, One Number and one special case Character"),
        confirmPassword: yup
            .string()
            .required('Confirm Password is Required')
            .oneOf([yup.ref('password'), null], 'Passwords must match'),
        role: yup.mixed().test('role', 'Role is Required', (checkbox: any) => {
            let check = false;
            if (checkbox) {
                for (const [key, value] of Object.entries(checkbox)) {
                    if (key && value && value !== '') {
                        check = true;
                        break;
                    }
                }
            }

            return check;
        })
    });
};

export const roleModalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3
};

export const userColumns = ({ deleteListItem, onEdit }: any) => [
    { header: langString('Name'), accessor: 'firstName' },
    { header: langString('Username'), accessor: 'username' },
    { header: langString('Email'), accessor: 'email' },
    {
        header: langString('Organization'),
        accessor: 'organization',
        content: (item: any) => <p> {item.organization.name} </p>
    },
    {
        header: langString('Role'),
        accessor: 'roles',
        content: (item: any) => <p> {item.roles.map((r: any) => r.name).join(',  ')} </p>
    },
    {
        header: 'Action',
        accessor: 'action',
        content: (item: any) => (
            <>
                <Button onClick={() => onEdit(item)} variant="outlined" color="primary" aria-label="upload picture" component="span">
                    <EditIcon />
                </Button>
                <ConfirmButton
                    variant="outlined"
                    buttonText={<DeleteForeverIcon />}
                    subTitle={`Delete User: ${item.firstName}`}
                    confirmed={() => deleteListItem(item.id)}
                />
            </>
        )
    }
];

export default {};
