import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase, Grid, Typography } from '@mui/material';

// project imports
import config from 'config';
// import Logo from 'ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <ButtonBase disableRipple component={Link} to={config.defaultPath}>
        <Grid>
            <Typography variant="h3">TrueCover</Typography>
        </Grid>
    </ButtonBase>
);

export default LogoSection;
