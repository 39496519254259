import { Language } from 'views/Projects/Details/Constants';

// action type
export const LANGUAGE_ADD = 'language/add';

// type of action

type add_language = typeof LANGUAGE_ADD;

type LanguageActionTypes = add_language;

// action - state management

interface Languages {
    languages: Language[];
}

const initialState: Languages = {
    languages: []
};
// ==============================|| Projects REDUCER ||============================== //

export interface IProjectsReducerActionProps {
    type: LanguageActionTypes;
    payload?: Language[] | Language;
}

const languageReducer = (state = initialState, action: IProjectsReducerActionProps) => {
    switch (action.type) {
        case LANGUAGE_ADD: {
            console.log(action.payload);
            return {
                ...state,
                languages: action.payload
            };
        }
        default: {
            return state;
        }
    }
};

export default languageReducer;

export const addLanguage = (lang: Language[] | null) => ({
    type: LANGUAGE_ADD,
    payload: lang
});
