import { Serializer } from 'jsonapi-serializer';
import axiosServices from 'utils/axiosServices';
import { _deserailize } from 'utils/Deserialize';
import { errMsg } from 'utils/Helpers';
import lodash from 'lodash';
import {
    organizationListURL,
    roleListURL,
    areaCreateURL,
    areaListURL,
    areaDetailURL,
    areaUpdateURL,
    areaDeleteURL
} from 'utils/serverUrls';

export const fetchOrgList = async (params: any, snackbarMsg: any) => {
    try {
        let organizations = await axiosServices.get(`${organizationListURL}`, { params });
        organizations = await _deserailize(organizations.data);
        return await new Promise((r, _) => r(organizations));
    } catch (ex) {
        snackbarMsg(errMsg(ex), 'error');
        return await new Promise((r, _) => r([]));
    }
};

export const fetchRoleList = async (params: any, snackbarMsg: any) => {
    try {
        let roles = await axiosServices.get(`${roleListURL}`, { params });
        roles = await _deserailize(roles.data);
        return await new Promise((r, _) => r(roles));
    } catch (ex) {
        snackbarMsg(errMsg(ex), 'error');
        return await new Promise((r, _) => r([]));
    }
};

export const fetchDataList = async (params: any, snackbarMsg: any) => {
    try {
        const response = await axiosServices.post(`${areaListURL}`, { ...params });
        const dataList = await _deserailize(response.data);
        return await new Promise((r, _) => r({ data: dataList, count: response.data.meta.count }));
    } catch (ex: any) {
        snackbarMsg(errMsg(ex), 'error');
        return await new Promise((r, _) => r({ data: [], count: 0 }));
    }
};

export const fetchDataDetail = async (body: any) => {
    try {
        const response = await axiosServices.post(`${areaDetailURL}`, { ...body });
        const data = await response.data;
        return await new Promise((r, _) => r({ data }));
    } catch (ex: any) {
        return await new Promise((r, _) => r({}));
    }
};

export const deleteArea = async (id: number | string, snackbarMsg: any) => {
    try {
        await axiosServices.post(`${areaDeleteURL}`, { id });
        snackbarMsg('Area deleted successfully.');
        return await new Promise((r, _) => r({}));
    } catch (ex: any) {
        snackbarMsg(errMsg(ex), 'error');
        return await new Promise((r, _) => r({}));
    }
};

export const editArea = async (values: any, snackbarMsg: any, navigate: any) => {
    const payload = lodash.cloneDeep(values);
    try {
        await axiosServices.post(`${areaUpdateURL}`, payload);
        snackbarMsg('Area Updated successfully.');
        navigate('/areas');
        return await new Promise((r, _) => r({}));
    } catch (ex: any) {
        snackbarMsg(errMsg(ex), 'error');
        return await new Promise((r, _) => r({}));
    }
};

export const addArea = async (values: any, snackbarMsg: any, navigate: any) => {
    const payload = lodash.cloneDeep(values);
    try {
        await axiosServices.post(`${areaCreateURL}`, payload);
        snackbarMsg('Area Created successfully.');
        navigate('/areas');
        return await new Promise((r, _) => r({}));
    } catch (ex: any) {
        snackbarMsg(errMsg(ex), 'error');
        return await new Promise((r, _) => r({}));
    }
};

export default {};
