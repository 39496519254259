import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';
// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import NewPassword from 'views/authentication/auth-forms/RegisterForm/NewPassword';
import VerifyEmail from 'views/authentication/auth-forms/RegisterForm/VerifyEmail';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('views/authentication/Register')));
const AuthForgotPassword = Loadable(lazy(() => import('views/authentication/ForgotPassword')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/register',
            element: <AuthRegister />
        },
        {
            path: '/forgot',
            element: <AuthForgotPassword />
        },
        {
            path: '/verify',
            element: <VerifyEmail />
        },
        {
            path: '/new-password',
            element: <NewPassword />
        }
    ]
};

export default LoginRoutes;
