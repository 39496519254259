import {
    Autocomplete,
    Button,
    Checkbox,
    FormControl,
    FormHelperText,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
    RadioGroup,
    Radio,
    FormControlLabel
} from '@mui/material';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import useSnackbar from 'hooks/useSnackbar';
import _ from 'lodash';
import { useEffect, useState, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { CURRENT_PAGE } from 'utils/Constants';
import { _deserailize } from 'utils/Deserialize';
import { initialValues, userValidationSchema, icon, checkedIcon } from './Constants';
import { addArea, editArea, fetchOrgList, fetchRoleList } from './Service';
import { styled } from '@mui/material/styles';
import LineMap from 'components/Map/LineMap';

const Input = styled('input')({
    display: 'none'
});

export default function AreaForm({ info = null }: any) {
    const [locationType, setLocationType] = useState('map');
    const [finalPolyline, setFinalPolyline] = useState([]);
    const [initPolygonValues, setInitPolygonValues] = useState([]);

    const [selectedFile, setSelectedFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [dataList, setDataList] = useState([]);
    const [fileRequredError, setFileRequredError] = useState(false);
    const [fileRequredStyle, setFileRequredStyle] = useState({});

    const snackbarMsg = useSnackbar();
    const navigate = useNavigate();

    // eslint-disable-next-line
    const isEdit = () => !(info === null);

    const onAdd = async (values: any) => {
        await addArea(values, snackbarMsg, navigate);
    };

    const onEdit = async (values: any) => {
        await editArea(values, snackbarMsg, navigate);
    };

    const submitData = async (values: any) => {
        if (locationType === 'file' && !selectedFile) {
            setFileRequredError(true);
            setFileRequredStyle({ border: '1px solid red', borderRadius: '13px' });
        }
        console.log('values ===>> ', values);
        if (!info) {
            values.locationType = locationType;
        }
        values.polygon = finalPolyline;
        isEdit() ? onEdit(values) : onAdd(values);
    };

    useEffect(() => {
        console.log(locationType);
        setFileRequredError(false);
        setFileRequredStyle({});
    }, [locationType]);

    useEffect(() => {
        if (selectedFile) {
            setFileRequredError(false);
            setFileRequredStyle({});
        }
    }, [selectedFile]);

    useEffect(() => {
        if (info) {
            setInitPolygonValues(info.data.polygon);
        }
    }, [info]);

    const formik = useFormik({
        initialValues: info == undefined || info == null ? initialValues : info.data,
        validationSchema: userValidationSchema(isEdit()),
        onSubmit: (values) => submitData(values)
    });

    const handleFileChange = (event: any) => {
        const file = event.currentTarget.files[0];
        setSelectedFile(file);

        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                const content = ('target' in event && !(event.target === null) && 'result' in event.target
                    ? event.target.result
                    : {}) as string;
                try {
                    const parsedData = JSON.parse(content);
                    if (parsedData && parsedData.data) {
                        setDataList(parsedData.data);
                    } else {
                        setDataList([]);
                        console.error('Invalid JSON structure');
                    }
                } catch (error) {
                    setDataList([]);
                    console.error('Error parsing JSON:', error);
                }
            };
            reader.readAsText(file);
        }
        setFileName(file.name);
    };

    return (
        <>
            {!isEdit() && (
                <Grid item xs={12}>
                    <Typography variant="h5">Select Location</Typography>
                    <RadioGroup
                        row
                        aria-labelledby="row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={(e) => setLocationType(e.target.value)}
                        value={locationType}
                    >
                        <Grid item xs={3}>
                            <FormControlLabel value="file" control={<Radio />} label="Upload Location File" />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel value="map" control={<Radio />} label="Select from Map" />
                        </Grid>
                    </RadioGroup>
                </Grid>
            )}
            {locationType && (
                <form onSubmit={formik.handleSubmit} autoComplete="off" style={{ marginTop: '30px' }}>
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <Grid item xs={6}>
                                <Typography variant="h5">Title of the Location</Typography>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    onChange={formik.handleChange}
                                    name="title"
                                    value={formik.values.title}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.title && Boolean(formik.errors.title)}
                                />
                                <FormHelperText style={{ color: 'red' }}>
                                    {formik.errors.title && formik.touched.title && formik.errors.title}
                                </FormHelperText>
                            </Grid>
                        </Grid>
                        {locationType === 'file' && (
                            <Grid item xs={12}>
                                <Grid item xs={6}>
                                    <Typography variant="h5">Upload Location File</Typography>
                                    <TextField
                                        variant="outlined"
                                        style={fileRequredStyle}
                                        fullWidth
                                        value={fileName}
                                        InputProps={{
                                            readOnly: true,
                                            startAdornment: (
                                                <>
                                                    <Input
                                                        accept=".json"
                                                        id="contained-button-file"
                                                        type="file"
                                                        onChange={handleFileChange}
                                                        style={{ display: 'none' }}
                                                    />
                                                    <label htmlFor="contained-button-file" style={{ justifySelf: 'end' }}>
                                                        <Button variant="contained" color="primary" component="span">
                                                            Browse
                                                        </Button>
                                                    </label>
                                                </>
                                            )
                                        }}
                                    />
                                </Grid>
                                {fileRequredError && <span style={{ color: 'red' }}>choose a file</span>}
                            </Grid>
                        )}
                        {locationType === 'file' && fileName !== '' && dataList.length > 0 && (
                            <Grid item xs={12}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">Catchment Area</Typography>
                                    <LineMap
                                        setFinalPolyline={setFinalPolyline}
                                        setChangeDetected={null}
                                        setPolygonList={null}
                                        initValues={dataList}
                                        search={false}
                                        editMain={false}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {locationType === 'map' && (
                            <Grid item xs={12}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">Catchment Area</Typography>
                                    <LineMap
                                        setFinalPolyline={setFinalPolyline}
                                        setChangeDetected={null}
                                        setPolygonList={null}
                                        initValues={initPolygonValues}
                                        search={isEdit() ? false : true}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Grid container spacing={2} direction="row-reverse">
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        type="submit"
                                        disabled={
                                            finalPolyline.length < 3 ||
                                            (finalPolyline[0][0] !== finalPolyline[finalPolyline.length - 1][0] &&
                                                finalPolyline[0][1] !== finalPolyline[finalPolyline.length - 1][1])
                                                ? true
                                                : false
                                        }
                                    >
                                        {' '}
                                        {isEdit() ? 'Edit' : 'Add'} Location{' '}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button component={Link} to="/areas" variant="contained" color="error">
                                        {' '}
                                        Cancel{' '}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            )}
        </>
    );
}
