import FileUploadIcon from '@mui/icons-material/FileUpload';
import { Button, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import useSnackbar from 'hooks/useSnackbar';
import { useState } from 'react';
import SubCard from 'ui-component/cards/SubCard';
import axiosFormService from 'utils/axiosFormServices';
import { getToken } from 'utils/Helpers';
import { projectFormUploadURL } from 'utils/serverUrls';

const Input = styled('input')({
    display: 'none'
});

export default function FormUpload({ handleClose }: any) {
    const [xlxFile, setXlxfile] = useState<any>('');
    const snackbarMsg = useSnackbar();

    const fileToBase64 = (file: any) => {
        console.log(file);
        setXlxfile(file);
    };

    const getFileName = () => {
        if (xlxFile)
            return (
                <>
                    {' '}
                    <b>File name:</b> {xlxFile.name}
                </>
            );
        return null;
    };

    const onSubmit = async () => {
        // if (project) {
        try {
            const payload = new FormData();
            payload.append('xls_file', xlxFile);
            // payload.append('project_id', project);

            const headers = {
                Authorization: `Bearer ${getToken('token')}`
            };
            await axiosFormService.post(`${projectFormUploadURL}`, payload);
            snackbarMsg('Sucessfully Uploaded');
            handleClose(false);
        } catch (ex: any) {
            // const msg = ex.response.data.errors.map((e: any) => e.detail).join(', ');
            snackbarMsg('Server Error !', 'error');
        }
        // }
    };

    return (
        <SubCard>
            <Grid container alignItems="center" justifyContent="flex-start" spacing={2}>
                {/* <Grid item xs={3}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Project</InputLabel>
                        <Select
                            labelId="simple-select-label"
                            id="simple-select"
                            label="Age"
                            value={project}
                            onChange={(e: any) => setProject(e.target.value)}
                        >
                            {projectList.map((p: any) => (
                                <MenuItem value={p.id}>{p.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid> */}
                <Grid item xs={2}>
                    {/* eslint-disable-line */}
                    <label htmlFor="contained-button-file">
                        <Input
                            accept="/*"
                            id="contained-button-file"
                            multiple
                            type="file"
                            onChange={(event: any) => {
                                fileToBase64(event?.currentTarget?.files?.[0]);
                            }}
                        />
                        <Button variant="contained" component="span" startIcon={<FileUploadIcon />}>
                            Upload
                        </Button>
                    </label>
                </Grid>
                <Grid item xs={1}>
                    <Button variant="contained" onClick={onSubmit}>
                        Submit
                    </Button>
                </Grid>
                <Grid item xs={5}>
                    {' '}
                </Grid>
                <Grid item xs={12}>
                    <span> {getFileName()}</span>
                </Grid>
            </Grid>
        </SubCard>
    );
}
